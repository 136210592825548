function modalidadesSinglePage() {
  /*Variables*/
  var $currentPageName = $(".page-main.page-current .page-toload"),
      modalidName = $currentPageName.attr("data-modname"),
      do_reset = false,
      $gymColumn = $(".gym-column"),
      page_loop = 0,
      mouseX = 0,
      sliderModalidades = null,
      container_offset = $(".modalidades .page-current .content-horarios .gym-column").width()*$(".modalidades .page-current .content-horarios .gym-column").length - _globalViewportW;

  /*DOM variables*/

  var init = function(do_reset) {

    if(_customScroll == null) {
      /*Attach the event with a reference to kill after exit */
      $_window.on("scroll.modalidadesSingle", rAF_scroll_ModalidadesSingleP);
    }
    else {
      _page_scroll_listener = function(status) {
        ModalidadesSingle_scroll_rAF(status);
      };

      _customScroll.addListener(_page_scroll_listener);
    }
    console.log("Modalidades-single page");

    /*inits*/
    do_reset = do_reset;
    page_loop = _rAF_loop(animationLoop);

    if ($_body.hasClass('mobile')) {
      var sliderModalidades = new Swiper ('.modalidades .page-current .content-horarios', {
        grabCursor: true,
        slidesPerView: 'auto',
        resistanceRatio: 0.5,
        navigation: {
            nextEl: '.modalidades .page-current .next.gym-btn',
            prevEl: '.modalidades .page-current .prev.gym-btn',
        },
        wrapperClass: 'scroll-wrapper-horizontal',
        slideClass: 'gym-column'
      })
    }

    fCalendar_ev_showClose();
    fCalendar_start();
    fCalendar_ev_estudios();
    fCalendar_ev_modalidades();

    //Events
    $_mainNav.attr("class","");
    $_mainNav.addClass(modalidName);
		if ( $_body.hasClass('mobile') || $_html.hasClass('safari') || $_html.hasClass('ios')) {
      $_mainNav.attr("class","");
    }

    // =audio
    if( !$_body.hasClass("mobile") ) {
        audioBtnShow(true);
        if ( _audioGlobal ) {
          if ( !_audiAllM.paused  ) {
            audioCrossFade(_audiAllM, _audioSingleM, 25);
          } else if (_audioSingleM.paused ) {
            _audioSingleM.pause();
            _audioSingleM.currentTime = 0;
            _audioSingleM.play();
            _audioSingleM.volume=1;
          }
        }
    }

    //DEFAULT EVENTS
    initEvents();
    if(!$_body.hasClass("mobile"))
      $_window.on("resize.resizeEventModalidadesSingle", $.debounce(500, resizeModalidadesSingle));

    if($_body.hasClass("mobile"))
      $_window.on("orientationchange.orientationEventModalidadesSingle", $.debounce(500, orientationModalidadesSingle));
  }

  var kill = function() {
    //Kill Events
    $_mainNav.removeClass(modalidName);
    window.cancelAnimationFrame(page_loop);
    $(".modalidades .page-current .content-horarios").off("mousemove");
    if(sliderModalidades)
      sliderModalidades.destroy();

    //DEFAULT KILL EVENTS
    $_window.off("scroll.modalidadesSingle");
    $_window.off("resize.resizeEventModalidadesSingle");
    $_window.off("orientationchange.orientationEventModalidadesSingle");

    _scrollRef = null;
    cancelAnimationFrame(_raf_main_id);

    if(_customScroll)
      _customScroll.removeListener(_page_scroll_listener);
  }

  /*page functions*/
  function initEvents() {
  	// SWIPE
    var $toswipe = $(".modality-pic, .modality-info, .modality-title-extra, .modality-info-list"),
        $nextBtn = $("#nav-main-control a.next"),
        $prevBtn = $("#nav-main-control a.prev");
    $toswipe.on("swiperight",function (e,data){
        $prevBtn.click();
        e.preventDefault();
    })
    $toswipe.on("swipeleft",function (e,data){
        $nextBtn.click();
        e.preventDefault();
    })

    /*Dar cor ao menu por causa das sobreposições. Ignorar se for safari pq tem outro efeito*/
    // if(!$_html.hasClass("safari")) {
    //   var bg = $_currentPage.find(".page-toload").css('background-color');
    //   if(bg.indexOf('a') == -1){
    //       var result = bg.replace(')', ', 0.95)').replace('rgb', 'rgba');
    //       $_mainHeader.css("background-color", result);
    //   }
    // }

    //Bloquear o evento do <a> do horario para não saltar para o topo da pagina
    var $horarioLink = $(".table-horarios a");
    $horarioLink.on("click", function(event){
      event.preventDefault();
    });

    var intensidade = $(".page-current .inten").attr("data-val");
    // $(".page-current .modality-info-list li").velocity("transition.expandIn", {
    //     duration: 250,
    //     delay: 340,
    //     stagger: 100
    // })
    // $(".page-current .modality-content").addClass('animModalityIn');

    var el_time_modalidade = $(".odometerTime")[0],
        timeString = $(".page-current .time").attr("data-val"),
        timeArray = timeString.split(","),
        timeHowMany = timeArray.length,
        numberToChange;

    //Reset to zero for modalities transition
    if (do_reset) {
      changeTimeModalityLoop(el_time_modalidade, 1, [00], true);
      $(".page-current .odometerTime").remove();
      $(".page-current .time .table-vh-center").html('<span class="table-cell odometerTime">00</span>');
      $(".page-current .time").attr("class", "time");
      $(".page-current .modality-info-list").addClass("click-mainBtn-reset");

      $(".page-current .inten").attr('class', 'inten');
      $(".page-current .target").removeClass("goAnim").addClass("exitAnim");
      return;
    }

    /* Info de modalidades */
    $.doTimeout("loopInfo", 0, function() {
        if (timeHowMany <= 1) {
            changeTimeModalityLoop(el_time_modalidade, timeHowMany, timeArray, true);
        } else {
            changeTimeModalityLoop(el_time_modalidade, timeHowMany, timeArray, false);
        }
    });

    $.doTimeout(350, function() {
        $(".page-current .inten").addClass("val-" + intensidade);
    });

    $.doTimeout(700, function() {
        $(".page-current .target").addClass("goAnim");
    });

    //Reinitiate SocialCount
    $(SocialCount.initSelector).each(function() {
        var $el = $(this);
        SocialCount.init($el);
    });

    // $(".gym-btn").on("mouseenter", function() {
    //   var $this = $(this);
    //   $this.find(".bg-hover").doTimeout("fOver", 100, "addClass", "over");
    //   event.preventDefault();
    // }).on("mouseleave", function() {
    //   var $this = $(this);
    //   $this.find(".bg-hover").doTimeout("fOver", 0, "removeClass", "over");
    //   event.preventDefault();
    // });

    $gymColumn.on("mouseenter", function(){
      var $this = $(this);
      if($this.hasClass('off')) return false;
      $this.find(".arrow-gyms").addClass("anim-arrow");
    })//end mouse enter

    $gymColumn.on("mouseleave", function(){
      var $this = $(this);
      if($this.hasClass('off')) return false;
      $this.find(".arrow-gyms").removeClass("anim-arrow");
    })//end mouse leave

    if (!$_body.hasClass('mobile')) {
      $(".modalidades .page-current .content-horarios").on("mousemove", function(event) {
        var $this = $(this);

        if(!$this.hasClass("stop-mousemove"))
          mouseX = event.clientX;
      })
    }
  }

  function changeTimeModality(el_time_modalidade, numberToChange, speed) {
		if (!el_time_modalidade) return;
		od_time_modalidade = new Odometer({
		    el: el_time_modalidade,
		    value: 00,
		    duration: speed,
		    format: '(dd)'
		});

		od_time_modalidade.update(numberToChange);
	} //end function


	function changeTimeModalityLoop(el_time_modalidade, timeHowMany, timeArray, stop) {
		var i = 0;
		var numberToChange = timeArray[i];
		$(".page-current .time").addClass("t" + numberToChange);
		$(".page-current .time").addClass("click-mainBtn");
		changeTimeModality(el_time_modalidade, numberToChange, 350);

		if (stop) {
		    $.doTimeout("loopTime");
		    return;
		}

		$.doTimeout("loopTime", 2500, function() {
	    i++;
	    $(".page-current .time").removeClass("click-mainBtn");

	    $.doTimeout(50, function() {
	        $(".page-current .time").addClass("click-mainBtn");
	        numberToChange = timeArray[i];
	        $(".page-current .time").addClass("t" + numberToChange);
	        changeTimeModality(el_time_modalidade, numberToChange, 350);
	    })

	    if (i > timeHowMany - 1) {
	        i = 0;
	        $(".page-current .time").removeClass("t30 t45 t60");
	        changeTimeModality(el_time_modalidade, "00", 350);
	        return true;
	    } else {
	        return true;
	    }
		}) //end main timeout
	} //end function

  function resizeModalidadesSingle() {
    container_offset = $(".modalidades .page-current .content-horarios .gym-column").width()*$(".modalidades .page-current .content-horarios .gym-column").length - _globalViewportW;
  }

  function orientationModalidadesSingle() {
    var sliderModalidades = document.querySelector('.modalidades .page-current .content-horarios').swiper;
    sliderModalidades.update();

    if($('.modalidades .page-current .content-horarios').hasClass("open")) {
      TweenMax.set($(".modalidades .page-current .content-horarios .gym-column"), {
        autoAlpha: 1,
        clearProps: "opacity, visibility"
      });
      $(".gym-btn").css("display", "");
      TweenMax.set($(".modalidades .page-current .content-horarios .gym-column.open").find(".click-area"), { autoAlpha: 1 });
      TweenMax.set($(".modalidades .page-current .content-horarios .gym-column.open"), { x: 0 });
      $('.modalidades .page-current .content-horarios').removeClass('open');
      $.doTimeout(400, function() {
        $('.modalidades .page-current .content-horarios').removeClass('stop-mousemove swiper-no-swiping');
        sliderModalidades.update();
      })
      $(".modalidades .page-current .content-horarios .gym-column.open").removeClass("open");
    }
  }

  function rAF_scroll_ModalidadesSingleP() {
    if (!$_body.hasClass('mobile')) {
      window.requestAnimationFrame(f_rAF_scroll_ModalidadesSingleP);
    }
  }

  function animationLoop() {
    page_loop = _rAF_loop(animationLoop);

    if (!$_body.hasClass('mobile')) {
      var offset = scaleBetween(mouseX, 0, container_offset, 0, _globalViewportW);

      TweenMax.to($(".page-current .scroll-wrapper-horizontal"), 1, {
        x : -offset,
        ease: Power4.easeOut
      })
    }
  }

  function f_rAF_scroll_ModalidadesSingleP(status) {
    // var $contentHorarios = $(".content-horarios").find(".grid-cell.open");

    // if(!$contentHorarios.length)
    //   return;
    //   content_horarios_top = $contentHorarios.offset().top - 80;
    // if (Modernizr.mq('(min-width: 768px)')) {
    //   if ( $_window.scrollTop() >= content_horarios_top )
    //       $contentHorarios.find(".btn-close").addClass("fix");
    //   else
    //     $contentHorarios.find(".btn-close").removeClass("fix");
    // }
  }

  return {
    init: init,
    kill: kill
  }
}