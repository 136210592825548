function corporatePage() {
  /*Variables*/
  var $form = $("#form-parceiro"),
      $formInputs = $("#form-parceiro input"),
      $submitForm = $form.find("button"),
      $tabsNav = $(".tabs nav"),
      $tabs = $(".tabs-list"),
      $tabsContentWrapper = $(".tabs-content-wrapper"),
      $tabsList = $(".tabs-list li"),
      $tabsContent = $(".tabs-content"),
      $radioButton = $(".radio-buttons li"),
      duration_height = 0;
      
  /*DOM variables*/

  var init = function() {

    if(_customScroll == null) {
      /*Attach the event with a reference to kill after exit */
      $_window.on("scroll.corporate", rAF_scroll_CorporateP);
    }
    else {
      _page_scroll_listener = function(status) {
        Corporate_scroll_rAF(status);
      };

      _customScroll.addListener(_page_scroll_listener);
    }
    console.log("corporate page");

    /*inits*/

    //Events
    audioBtnShow(false);

    //DEFAULT EVENTS
    initEvents();
    if(!$_body.hasClass("mobile"))
      $_window.on("resize.resizeEventCorporate", $.debounce(500, resizeCorporate));
  }

  var kill = function() {
    //Kill Events

    //DEFAULT KILL EVENTS
    $_window.off("scroll.corporate");
    $_window.off("resize.resizeEventCorporate");
    
    _scrollRef = null;
    cancelAnimationFrame(_raf_main_id);

    if(_customScroll)
      _customScroll.removeListener(_page_scroll_listener);
  }

  /*page functions*/
  function initEvents() {
    $tabsList.on("click", function(){
      var $this = $(this),
          target =$this.data("target"),
          delay = 800,
          distance_top = $tabsContentWrapper.offset().top - verge.scrollY();

      if(distance_top > 71)
        delay = 0;

      if( Modernizr.mq('(min-width: 1025px)') && QA_scene.enabled() ){
        goTo( $tabsContentWrapper, 500, [0.7,0,0.3,1], -72 );
      }
      $.doTimeout(delay, function(){
        $tabsList.removeClass("on");
        $this.addClass("on");
        $tabsContent.removeClass("on");
        $(target).addClass("on");
      });
    });

    $form.find(".rgpd-wrapper svg").on("click", function() {
      var $this = $(this);
      $this.closest("div").toggleClass("active");

      if($this.closest("div").hasClass("active")){
        $this.closest("div").find("input").prop('checked', true);
        $form.find("button").addClass("enable");
      } else{
        $this.closest("div").find("input").prop('checked', false);
        $form.find("button").removeClass("enable");
      }
    });

    $radioButton.on("click", function(){
      var $this = $(this),
          inputChecked = $this.data('check');
  
      if($this.find(".radio-selection").hasClass("selected")){
        $this.find(".radio-selection").removeClass("selected").addClass("unselected");
        $form.find('input[data-check-input="' + inputChecked + '"]').attr('checked', false);
      }
      else {
        $this.find(".radio-selection").removeClass("unselected").addClass("selected");
        $form.find('input[data-check-input="' + inputChecked + '"]').attr('checked', true);
      }
    });

    $formInputs.blur(function() {
      var $this = $(this),
          defeito = this.defaultValue;

      if(!$this.hasClass("required") && !$this.val() ) return;
      check($this, defeito, 0);

      if ($this.hasClass('erro')) {
          $this.parent().addClass('erro');
      } else {
          $this.parent().removeClass('erro');
      }
    });

    $formInputs.focus(function() {
        var $this = $(this);
        $this.removeClass('erro');
        $this.parent().removeClass('erro');
    });

    /*Submit Form*/
    $submitForm.on("click", function(){
      event.preventDefault();
        if (validateForm($form) && !$form.hasClass('sending')) {
          formAnimLoadingNEW($submitForm, 'loading');
          $form.addClass('sending');
          // campainMonitorForm($form);

          console.log($form.serialize());
          $.doTimeout(2700, function() {
            $.ajax({

              type: 'GET',
              dataType: 'xml',                
              url: $form.attr('action') + $form.serialize(),

              // data: $form.serialize(),
              // type: $form.attr('method'),
              // dataType: 'json',
              // url: $form.attr('action'),
              // cache: false,
              // contentType: 'application/json; charset=utf-8',
              success: function(data) {
                // console.log("sent", data);
                if (data.getElementsByTagName("status")[0].textContent == 'success') {                                                                                    
                  if(data.getElementsByTagName("ERROR").length > 0){                      
                    formAnimLoadingNEW($submitForm, 'sent-error');
                    $form.removeClass('sending');
                  } else {
                    formAnimLoadingNEW($submitForm, 'sent-ok');
                    $form.removeClass('sending');
                  }
                }
                else{
                  formAnimLoadingNEW($submitForm, 'sent-error');
                  $form.removeClass('sending');
                }

                  // if (data.result == 'success') {
                  //     formAnimLoadingNEW($submitForm, 'sent-ok');
                  //     $form.removeClass('sending');
                  // } else {
                  //     formAnimLoadingNEW($submitForm, 'sent-error');
                  //     $form.removeClass('sending');
                  // }
              },
              error: function(data) {
                // console.log("erro", data);
                formAnimLoadingNEW($submitForm, 'sent-error');
                $form.removeClass('sending');
              }
            });
          })//end loading simulation
        }
    });

    var QA_controller = new ScrollMagic.Controller();

    var QA_scene = new ScrollMagic.Scene({triggerElement: ".corporate-parcerias .tabs-content-wrapper", triggerHook: "onLeave", duration: parcerias_getQAheight })
      .setPin(".corporate-parcerias .tabs-list")
      .addTo(QA_controller)
      .offset(-72);

      if( Modernizr.mq('(max-width: 1023px)') ) {
        QA_scene.enabled(false);
      }
      if( $_html.hasClass("mobile") ) {
        QA_scene.enabled(false);
        QA_scene.destroy(true);
      }

    function parcerias_getQAheight() {
      var $tabsContentLast = $(".corporate-parcerias .tabs-content.on li:last-child");
      if ( !$tabsContentLast.length) return;
      if( Modernizr.mq('(max-width: 1023px)') )
        duration_height = 0;
      else
        duration_height = $tabsContentLast.position().top - 210;
      return duration_height;
    }

    var $gymLink = $(".corporate .content-2c-fullH-gym .visual a");

    $gymLink.on("mouseenter", function(){
      var $this = $(this);
      $this.parent().addClass('over');
    })
    $gymLink.on("mouseleave", function(){
      var $this = $(this);
      $this.parent().removeClass('over');
    })
  }

  function resizeCorporate() {
    if( Modernizr.mq('(max-width: 1023px)') && QA_scene.enabled() ){
      QA_scene.enabled(false);
    }
    else if( Modernizr.mq('(min-width: 1024px)') && !QA_scene.enabled() ){
      QA_scene.enabled(true);
    }
  }

  function rAF_scroll_CorporateP() {
    if (!$_body.hasClass('mobile')) {
      window.requestAnimationFrame(f_rAF_scroll_CorporateP);
    }
  }

  function f_rAF_scroll_CorporateP(status) {

  }

  return {
    init: init,
    kill: kill
  }
}