function blogAllPage() {
  /*Variables*/
  var $jsLoadMore = $('.js-load-more'),
      $list = $(".nutricao-list > .block-content");

  /*DOM variables*/

  var init = function() {

    if(_customScroll == null) {
      /*Attach the event with a reference to kill after exit */
      $_window.on("scroll.blogAll", rAF_scroll_BlogAllP);
    }
    else {
      _page_scroll_listener = function(status) {
        blogAll_scroll_rAF(status);
      };

      _customScroll.addListener(_page_scroll_listener);
    }
    console.log("blog-all page");

    /*inits*/

    //Events
    audioBtnShow(false);

    //DEFAULT EVENTS
    initEvents();
    if(!$_body.hasClass("mobile"))
      $_window.on("resize.resizeEventBlogAll", $.debounce(500, resizeBlogAll));
  }

  var kill = function() {
    //Kill Events

    //DEFAULT KILL EVENTS
    $_window.off("scroll.blogAll");
    $_window.off("resize.resizeEventBlogAll");
    
    _scrollRef = null;
    cancelAnimationFrame(_raf_main_id);

    if(_customScroll)
      _customScroll.removeListener(_page_scroll_listener);
  }

  /*page functions*/
  function initEvents() {
    $jsLoadMore.on("click", function(event) {
      event.stopImmediatePropagation();
      event.stopPropagation();
      event.preventDefault();

      //var url = $(this).attr('href');
      var url = $(this).attr('data-ajaxUrl');

      $.get(url, function(data) {
        var $source = $('<div>' + data + '</div>');
        var $load_more_link = $source.find('.js-load-more');

        $source.find('.nutricao-list > .block-content article').each(function(index){
          $(this).attr("id", "js-loaded-" + index).addClass("js-loaded");
        });
        $list.append($source.find('.nutricao-list > .block-content > *')
          .velocity({
            opacity: [1, 0]
          }, {
            duration: 200,
            easing: "ease",
            complete: function(){
              //Facebook Comments
              $list.find('.js-loaded').each(function(index){
                var id = "js-loaded-" + index;
                FB.XFBML.parse(document.getElementById(id));
              }).removeClass("js-loaded").attr("id","");

            }
          }));

        if ($load_more_link.length > 0) {
          $(".js-load-more").show();
          $(".js-load-more").attr("href", $load_more_link.attr("href"));
          $(".js-load-more").attr("data-ajaxUrl", $load_more_link.attr("data-ajaxUrl"));
        } else {
          $(".js-load-more").hide();
        }
      });
    });

    //Facebook Comments
    (function(d, s, id) {
      var js, fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) return;
      js = d.createElement(s); js.id = id;
      js.async=true;
      js.src = "//connect.facebook.net/pt_PT/sdk.js#xfbml=1&version=v2.5";
      fjs.parentNode.insertBefore(js, fjs);

    }(document, 'script', 'facebook-jssdk'));

    if(!$_body.hasClass("js-no-ajax")){
      $.doTimeout(200, function(){
        if(FB)
          FB.XFBML.parse();
      });
    }
  }

  function resizeBlogAll() {

  }

  function rAF_scroll_BlogAllP() {
    if (!$_body.hasClass('mobile')) {
      window.requestAnimationFrame(f_rAF_scroll_BlogAllP);
    }
  }

  function f_rAF_scroll_BlogAllP(status) {
    
  }

  return {
    init: init,
    kill: kill
  }
}