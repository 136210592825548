function recrutaPage() {
  /*Variables*/
  var $form = $(".recruta-form"),
      $formInputs = $(".recruta-form input"),
      $formFileName = $('.js-file-name'),
      $submitForm = $form.find("button"),
      server_hostname = document.location.origin;

  /*DOM variables*/

  var init = function() {

    if(_customScroll == null) {
      /*Attach the event with a reference to kill after exit */
      $_window.on("scroll.recruta", rAF_scroll_RecrutaP);
    }
    else {
      _page_scroll_listener = function(status) {
        recruta_scroll_rAF(status);
      };

      _customScroll.addListener(_page_scroll_listener);
    }
    console.log("recruta page");

    /*inits*/

    //Events
    audioBtnShow(false);

    //DEFAULT EVENTS
    initEvents();
    if(!$_body.hasClass("mobile"))
      $_window.on("resize.resizeEventRecruta", $.debounce(500, resizeRecruta));
  }

  var kill = function() {
    //Kill Events

    //DEFAULT KILL EVENTS
    $_window.off("scroll.recruta");
    $_window.off("resize.resizeEventRecruta");
    
    _scrollRef = null;
    cancelAnimationFrame(_raf_main_id);

    if(_customScroll)
      _customScroll.removeListener(_page_scroll_listener);
  }

  /*page functions*/
  function initEvents() {
    $jsUpload = $(".js-upload"),
    $file = $('input[type=file]');

    $jsUpload.on("click", function() {
      $file.click();
    });

    $formInputs.blur(function() {
      var $this = $(this),
          defeito = this.defaultValue;

      if(!$this.hasClass("required") && !$this.val() ) return;
      check($this, defeito, 0);

      if ($this.hasClass('erro')) {
          $this.parent().addClass('erro');
      } else {
          $this.parent().removeClass('erro');
      }
    });

    $formInputs.focus(function() {
        var $this = $(this);
        $this.removeClass('erro');
        $this.parent().removeClass('erro');
    });

    $file.change(function(e) {
      var file = $(document).find('input[type="file"]');
      var individual_file = file[0].files[0];
      var file_name = $(".recruta-file-name");

      $formFileName.html(individual_file["name"]);
      file_name.css("color", "#242a30");
      file_name.parent().removeClass("error");
    });

    $form.find(".rgpd-wrapper svg").on("click", function() {
      var $this = $(this);
      $this.closest("div").toggleClass("active");

      if($this.closest("div").hasClass("active")){
        $this.closest("div").find("input").prop('checked', true);
        $form.find("button").addClass("enable");
      } else{
        $this.closest("div").find("input").prop('checked', false);
        $form.find("button").removeClass("enable");
      }
    });

    /*Submit Form*/
    $submitForm.on("click", function(){
      event.preventDefault();

      if (validateForm($form) && !$form.hasClass('sending')) {
        $form.addClass('sending');
        formAnimLoadingNEW($submitForm, 'loading');

        var fd = new FormData();
        var file = $(document).find('input[type="file"]');
        var individual_file = file[0].files[0];

        fd.append("recruta-form-proposal-name", $form.find('input[name="recruta-form-proposal-name"]').val());
        fd.append("recruta-form-proposal", $form.find('input[name="recruta-form-proposal"]').val());
        fd.append("recruta-form-nome", $form.find('input[name="recruta-form-nome"]').val());
        fd.append("recruta-form-email", $form.find('input[name="recruta-form-email"]').val());
        fd.append("recruta-form-contacto", $form.find('input[name="recruta-form-contacto"]').val());
        fd.append("recruta-form-localidade", $form.find('input[name="recruta-form-localidade"]').val());
        fd.append("recruta-form-mensagem", $form.find('textarea').val());

        fd.append("file", individual_file);
        fd.append("_wp_http_referer", $form.find('input[name="_wp_http_referer"]').val());
        fd.append("recruta_nonce", $form.find('input[name="recruta_nonce"]').val());
        fd.append('action', 'initRecruta');

        $.doTimeout(2700, function() {
          $.ajax({
            data: fd,
            type: 'post',
            contentType: false,
            processData: false,
            url: server_hostname + '/wp-admin/admin-ajax.php',
            success: function(data) {
              formAnimLoadingNEW($submitForm, 'sent-ok');
              $form.removeClass('sending');
            },
            error: function(data) {
              formAnimLoadingNEW($submitForm, 'sent-error');
              $form.removeClass('sending');
            }
          });
        });
      }
    });
  }

  function resizeRecruta() {

  }

  function rAF_scroll_RecrutaP() {
    if (!$_body.hasClass('mobile')) {
      window.requestAnimationFrame(f_rAF_scroll_RecrutaP);
    }
  }

  function f_rAF_scroll_RecrutaP(status) {

  }

  return {
    init: init,
    kill: kill
  }
}