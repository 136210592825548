function blogSinglePage() {
  /*Variables*/

  /*DOM variables*/

  var init = function() {

    if(_customScroll == null) {
      /*Attach the event with a reference to kill after exit */
      $_window.on("scroll.blogSingle", rAF_scroll_BlogSingleP);
    }
    else {
      _page_scroll_listener = function(status) {
        blogSingle_scroll_rAF(status);
      };

      _customScroll.addListener(_page_scroll_listener);
    }
    console.log("blog-single page");

    /*inits*/

    //Events
    audioBtnShow(false);

    //DEFAULT EVENTS
    initEvents();
    if(!$_body.hasClass("mobile"))
      $_window.on("resize.resizeEventBlogSingle", $.debounce(500, resizeBlogSingle));
  }

  var kill = function() {
    //Kill Events

    //DEFAULT KILL EVENTS
    $_window.off("scroll.blogSingle");
    $_window.off("resize.resizeEventBlogSingle");
    
    _scrollRef = null;
    cancelAnimationFrame(_raf_main_id);

    if(_customScroll)
      _customScroll.removeListener(_page_scroll_listener);
  }

  /*page functions*/
  function initEvents() {
    //Facebook Comments
    (function(d, s, id) {
      var js, fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) return;
      js = d.createElement(s); js.id = id;
      js.async=true;
      js.src = "//connect.facebook.net/pt_PT/sdk.js#xfbml=1&version=v2.5";
      fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'facebook-jssdk'));

    if(!$_body.hasClass("js-no-ajax"))
      $.doTimeout(200, function(){
        FB.XFBML.parse();
      });
  }

  function resizeBlogSingle() {

  }

  function rAF_scroll_BlogSingleP() {
    if (!$_body.hasClass('mobile')) {
      window.requestAnimationFrame(f_rAF_scroll_BlogSingleP);
    }
  }

  function f_rAF_scroll_BlogSingleP(status) {
    
  }

  return {
    init: init,
    kill: kill
  }
}