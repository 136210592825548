/*! -------------------------------------------------------------------------------------------
JAVASCRIPT main engine!

* @Version:    1.0 - 2015
* @author:     Burocratik (alexandre gomes - @alexrgomes)
* @email:      alex@burocratik.com, hello@burocratik.com
* @website:    http://www.burocratik.com
* @preserve
NOTES:
:: js-no-ajax class on body (nao pode ser no html) > take it off with js as soon I work with ajax
:: js-no-ajax = did refresh
:: body.js-byrefresh= when i start by direct link (refresh) do no show content before loading
:: #loading-page.js-loading-page = i need separate byrefresh of this when I have js off
:: js-loading-page = can be used if I need a global style only when I am loading content
:: mobile = tag html is via js, tag body is via php (can't be on html tag or is deleted), also used for IE<=10
:: _global_allowNavigate = do not allow multiple cliks to load ajax (arrow, keys, click)
:: js-no-transPage = when I want a domain link with no transition ajax animation
:: data-ajaxUrl = url dinamico quando uso ajax (WP with cache), onde so me carrega parte do conteudo
--------------------------------------------------------------------------------------------*/
$(document).ready(function () {
  //** outdatedbrowser.com (the first to be call or in older browsers IE6,7 will have weird js erros )
  outdatedBrowser({
    bgColor: "#f25648",
    color: "#fff",
    lowerThan: "transform",
    languagePath: "",
  });
  //** MODERNIZR not supporter properties (backgroundcliptext, object-fit, cssclipPath )
  Modernizr.addTest("backgroundcliptext", function () {
    var div = document.createElement("div");
    div.style.cssText = Modernizr._prefixes.join("background-clip:text;");
    return !!div.style.cssText.replace(/\s/g, "").length;
  });

  Modernizr.addTest("object-fit", !!Modernizr.prefixed("objectFit"));

  !(function (e) {
    for (
      var t,
        n,
        i,
        r = [
          { name: "svg", value: "url(#test)" },
          { name: "inset", value: "inset(10px 20px 30px 40px)" },
          { name: "circle", value: "circle(60px at center)" },
          { name: "ellipse", value: "ellipse(50% 50% at 50% 50%)" },
          { name: "polygon", value: "polygon(50% 0%, 0% 100%, 100% 100%)" },
        ],
        p = 0;
      p < r.length;
      p++
    )
      (t = r[p].name),
        (n = r[p].value),
        e.addTest("cssclippath" + t, function () {
          if ("CSS" in window && "supports" in window.CSS) {
            for (var t = 0; t < e._prefixes.length; t++)
              if (
                ((i = e._prefixes[t] + "clip-path"), window.CSS.supports(i, n))
              )
                return !0;
            return !1;
          }
          return e.testStyles(
            "#modernizr { " + e._prefixes.join("clip-path:" + n + "; ") + " }",
            function (t) {
              var n = getComputedStyle(t),
                i = n.clipPath;
              if (!i || "none" == i) {
                i = !1;
                for (var r = 0; r < e._domPrefixes.length; r++)
                  if (
                    ((test = e._domPrefixes[r] + "ClipPath"),
                    n[test] && "none" !== n[test])
                  ) {
                    i = !0;
                    break;
                  }
              }
              return e.testProp("clipPath") && i;
            }
          );
        });
  })(Modernizr);
  /////////////////////////
}); //END LOAD DOCUMENT

/********************************************************************************************
     =NAVIGATION =MAIN
*********************************************************************************************/
function mainNavigation() {
  var close = null;
  $_btn_mainNav.on("click", function (event) {
    event.stopImmediatePropagation();
    $_body.hasClass("open-nav") ? (close = true) : (close = false);
    mainNav_controll(close);
    $("html,body").toggleClass("open-nav");
    event.stopPropagation();
    event.preventDefault();

    if ($_body.hasClass("open-nav")) {
      disableBodyScroll(true);
    } else {
      disableBodyScroll(false);
    }

    return false;
  });
  $_mainHeaderSlide.on("clickoutside", function (event) {
    event.stopImmediatePropagation();
    $_body.hasClass("open-nav") ? (close = true) : (close = false);
    if (close == false) return false;
    mainNav_controll(close);
    $("html,body").toggleClass("open-nav");
    event.stopPropagation();
    event.preventDefault();

    if ($_body.hasClass("open-nav")) {
      disableBodyScroll(true);
    } else {
      disableBodyScroll(false);
    }

    return false;
  });
} // end function

function mainNav_controll(close) {
  if ($_html.hasClass("no-csstransforms3d")) return; // <IE10
  if (Modernizr.mq("(max-width: 699px)")) return;
  var Yi = 0,
    Yf = "258px",
    Oi = 0,
    Of = 0.25,
    show = "block";
  if (close) {
    Yi = "258px";
    Yf = 0;
    Oi = 0.25;
    Of = 0;
    show = "none";
  }

  $_mainHeaderSlide.velocity(
    {
      translateZ: 0,
      translateY: [Yf, Yi],
    },
    {
      duration: 300,
      //easing: [1, 0, 0, 1],
      easing: [0.17, 0.89, 0.16, 0.99],
      mobileHA: true,
    }
  );

  $_mainHeaderBg.velocity(
    {
      opacity: [Of, Oi],
    },
    {
      duration: 350,
      easing: "ease-in-out",
      display: show,
    }
  );
} //end

/* *******************************************************************************************
 **                                                                                       **
      =LOADING PAGES, SECTIONS - =transitions between pages, =ajax
 **                                                                                       **
*********************************************************************************************/
//** MAIN LOAD
function loadPages(newContent, pageDirection) {
  var $currentPage = $(".page-main.page-current"),
    $nextContent = $(".page-main.page-next"),
    $prevContent = $(".page-main.page-prev"); // can't be global
  var nextPageAlreadyLoaded = false;

  $("html,body").addClass("js-fixed-all");
  $_transition.show().css("opacity", "1");
  $_body.removeClass("js-no-ajax"); // I am using =ajax

  if (_currentView) {
    _currentView.kill();
    _currentView = null;
  }

  //** CASE 1: I am in a section with multiple loadings, i am sure i have content (by refresh or not)
  // OLD transition between modalities (case 1, but used for normal transition)
  if (pageDirection != "topDown") {
    // Am I going to a page already loaded (note the images preload is always done)
    nextPageAlreadyLoaded = fcheckWhereTo(newContent);
    if (nextPageAlreadyLoaded) {
      transitionPagesAnimOut(pageDirection);
      transitionPagesAnimIn(pageDirection);
      //change html
      $.doTimeout(1700, function () {
        $currentPage.remove();
        if (pageDirection === "next") {
          $prevContent.remove();
          $nextContent.removeClass("page-next");
          $nextContent.addClass("page-current").removeAttr("aria-hidden");
        } else {
          $nextContent.remove();
          $prevContent.removeClass("page-prev");
          $prevContent.addClass("page-current").removeAttr("aria-hidden");
        }
        var $newCurrentPage = $(".page-main.page-current"),
          $newToPreload = $(".page-current .preload");
        $newCurrentPage.after(
          '<div class="page-main page-prev" aria-hidden="true"></div>'
        );
        $newCurrentPage.after(
          '<div class="page-main page-next" aria-hidden="true"></div>'
        );
        $newCurrentPage.attr("style", "");
        onStartPagewhenRefresh(false);

        // var nextContent = $newToPreload.find("a.next").attr('href'),
        //     prevContent = $newToPreload.find("a.prev").attr('href');
        var nextContent = $newToPreload.find("a.next").attr("data-ajaxUrl"),
          prevContent = $newToPreload.find("a.prev").attr("data-ajaxUrl");

        loadPagesNextPrev(prevContent, nextContent);
      }); //end do timeout
      return false;
    }
    //return false;
  } //end case 1

  //** CASE 2: I am and going to a normal page OR from normal page no one with multiple loadings (clearPagesAfterloading)
  transitionPagesAnimOut(pageDirection);
  //need give time for the svg morthing, or will be on top of transparent
  $.doTimeout(500, function () {
    loadingLogoAnim($_transition, "show");
  });

  var nextContent = newContent,
    prevContent = null;
  _isLoadedPageAjax = false;

  loadPagesNextPrev(prevContent, nextContent);

  $.doTimeout(150, function () {
    if (_isLoadedPageAjax) {
      manageBodyClasses();
      classesForNavigation(); //logo and arrows navigation
      transitionPagesAnimIn(pageDirection);
      clearPagesAfterloading(1700);
      return false;
    }
    return true;
  });
  return false;
} //////end function main load content

//***** =LOAD PAGES LIKE =MODALITIES WITH MULTIPLE PAGES PRELOADING (like between each modality) ** /
// Note: when this section is load I have alread the prev and next html content!
function loadPagesFlip(newContent, $element, pageDirection, pageColor) {
  var $currentPage = $(".page-main.page-current"),
    $nextContent = $(".page-main.page-next"),
    $prevContent = $(".page-main.page-prev"); // can't be global
  /// change content variables
  var $currentPic = $(".page-main.page-current .modality-pic-current"),
    $newPicNext = $(".page-main.page-current .modality-pic-next"),
    $newPicPrev = $(".page-main.page-current .modality-pic-prev"),
    newPicNextHtml = $(".page-main.page-next .modality-pic-current").html(),
    newPicPrevHtml = $(".page-main.page-prev .modality-pic-current").html();
  var $currentTitle = $(".page-main.page-current .modality-title.current"),
    newTitleNextHtml = $(".page-main.page-next .modality-title.current").html(),
    newTitlePrevHtml = $(".page-main.page-prev .modality-title.current").html();
  // var $nextTitleExtra = $(".page-main.page-current .modality-title-extra.next span"),
  //     $prevTitleExtra = $(".page-main.page-current .modality-title-extra.prev span"),
  //     nextTitleExtraText = $(".page-main.page-next .modality-title-extra.current span").text(),
  //     prevTitleExtraText = $(".page-main.page-prev .modality-title-extra.current span").text();
  var $nextTitleExtra = $(".page-main.page-current .modality-title-extra.next"),
    $prevTitleExtra = $(".page-main.page-current .modality-title-extra.prev"),
    nextTitleExtraText = $(
      ".page-main.page-next .modality-title-extra.current"
    ).html(),
    prevTitleExtraText = $(
      ".page-main.page-prev .modality-title-extra.current"
    ).html();

  var $toAnimate = $(".page-main.page-current .transition-modalities"),
    $toAnimateBg = $(".page-main.page-current .transition-modalities-bg"),
    classToAddpic = null,
    classToAddTitle = null,
    yF = 0,
    yI = null,
    skI = null,
    $horarios = $(".page-main.page-current .content-horarios"),
    currentPicH = $currentPic.height(),
    viewportPlus50W = Math.round(_globalViewportW + _globalViewportW / 2);

  $("html,body").addClass("js-fixed-all");
  $_body.removeClass("js-no-ajax"); // I am using =ajax

  //insert html, classes etc depending of direction (not for clip: rect, animation not working when html inject via js)
  if (pageDirection == "next") {
    $newPicNext.append(newPicNextHtml);
    $currentTitle.after(
      '<div class="modality-title next">' + newTitleNextHtml + "</div>"
    );
    //$nextTitleExtra.text(nextTitleExtraText);
    $nextTitleExtra.append(nextTitleExtraText);
    //
    classToAddpic = "exit-goingToRght";
    classToAddTitle = "exit-goingToRght-title";
    yI = _globalViewportW;
    yF = "0";
    skI = "-25deg";
    //
    var newPicHeight = $newPicNext.height();
  } else {
    $newPicPrev.append(newPicPrevHtml);
    $currentTitle.after(
      '<div class="modality-title next">' + newTitlePrevHtml + "</div>"
    );
    // $prevTitleExtra.text(prevTitleExtraText);
    $prevTitleExtra.append(prevTitleExtraText);
    //
    classToAddpic = "exit-goingToLft";
    classToAddTitle = "exit-goingToLft-title";
    yI = -_globalViewportW - 500;
    yF = "-200px";
    skI = "25deg";
    //
    var newPicHeight = $newPicPrev.height();
  }

  // reset modality-info-list & reset over arrows

  _currentView.kill();
  _currentView = modalidadesSinglePage();
  _currentView.init(true);
  fArrowsNav(false);
  $_body.addClass("js-loading-page");

  // action
  $(".page-main.page-current .modality-pic-wrapper").addClass(classToAddpic);
  $(".page-main.page-current .modality-info").addClass(classToAddTitle);
  $(".page-main.page-current .modality-title-extra-wrapper").addClass(
    classToAddTitle
  );

  $toAnimate.addClass(pageColor);
  $toAnimate.velocity(
    {
      translateZ: 0,
      translateX: [yF, yI],
    },
    {
      duration: 490,
      // easing: [.27, .54, .6, .99],
      // easing: "[.57,0,.34,.98]",
      easing: "[.11,.7,.01,1.03]",
      mobileHA: true,
      delay: 0,
    }
  );

  $toAnimateBg.velocity(
    {
      translateZ: 0,
      skewX: [0, skI],
    },
    {
      duration: 550,
      // easing: "ease-out",
      easing: "[.11,.7,.01,1.03]",
      mobileHA: true,
      delay: 100,
      complete: function () {
        resetLoadedPageFlip();
        $("html,body").removeClass("js-fixed-all");
      },
    }
  );

  var yHorariosF = newPicHeight - currentPicH;
  if (!yHorariosF) {
    yHorariosF = 0;
  }
  // $horarios.velocity({
  //     translateZ: 0,
  //     translateY: [yHorariosF, 0]
  // }, {
  //         duration: 300,
  //         easing: "ease-out",
  //         delay: 100
  //     })

  /// end of animation - reset stage
  function resetLoadedPageFlip() {
    $.doTimeout(90, function () {
      $currentPage.remove();
      if (pageDirection === "next") {
        $prevContent.remove();
        $nextContent.removeClass("page-next");
        $nextContent.addClass("page-current").removeAttr("aria-hidden");
      } else {
        $nextContent.remove();
        $prevContent.removeClass("page-prev");
        $prevContent.addClass("page-current").removeAttr("aria-hidden");
      }
      var $newCurrentPage = $(".page-main.page-current"),
        $newToPreload = $(".page-current .preload");
      $newCurrentPage.after(
        '<div class="page-main page-prev" aria-hidden="true"></div>'
      );
      $newCurrentPage.after(
        '<div class="page-main page-next" aria-hidden="true"></div>'
      );
      $newCurrentPage.attr("style", "");

      // var nextContent = $newToPreload.find("a.next").attr('href'),
      //     prevContent = $newToPreload.find("a.prev").attr('href');
      var nextContent = $newToPreload.find("a.next").attr("data-ajaxUrl"),
        prevContent = $newToPreload.find("a.prev").attr("data-ajaxUrl");

      // NEED TO BE SURE THAT PRELOAD IS DONE!
      _isLoadedPageAjax = false;
      _global_allowNavigate = false;

      loadPagesNextPrev(prevContent, nextContent);

      _currentView.kill();
      _currentView = modalidadesSinglePage();
      _currentView.init(false);

      $.doTimeout(40, function () {
        if (_isLoadedPageAjax) {
          onStartPagewhenRefresh(false);
          // $("html,body").removeClass("js-fixed-all");
          $_body.removeClass("js-loading-page");
          return false;
        }
        return true;
      }); //END if preloading next/prev pages done?
    }); //end maintimeout
  } //end function resetLoadedPageFlip
} //////end Function loadPages

//***** =LOAD PAGES =TEAM =INSTRUTORES, like  MODALITIES WITH MULTIPLE PAGES PRELOADING (like between each modality) ** /
// Note: when this section is load I have alread the prev and next html content!
function loadPagesFlipTeam(newContent, $element, pageDirection) {
  var $currentPage = $(".page-main.page-current"),
    $nextContent = $(".page-main.page-next"),
    $prevContent = $(".page-main.page-prev"); // can't be global
  /// change content variables
  var $currentPic = $(".page-main.page-current .modality-pic-current"),
    $newPicNext = $(".page-main.page-current .modality-pic-next"),
    $newPicPrev = $(".page-main.page-current .modality-pic-prev"),
    newPicNextHtml = $(".page-main.page-next .modality-pic-current").html(),
    newPicPrevHtml = $(".page-main.page-prev .modality-pic-current").html();

  var $currentInfo = $(".page-main.page-current .instruct-info.current"),
    $newNextInfo = $(".page-main.page-current .instruct-info.next"),
    $newPrevInfo = $(".page-main.page-current .instruct-info.prev"),
    newNextInfo = $(".page-main.page-next .instruct-info.current").html(),
    newPrevInfo = $(".page-main.page-prev .instruct-info.current").html();

  var $objTxt = null,
    yF = 0,
    yI = null,
    xI = null;

  $("html,body").addClass("js-fixed-all");
  $_body.removeClass("js-no-ajax"); // I am using =ajax
  fArrowsNav(false); // reset over arrows
  $_body.addClass("js-loading-page");

  //NOTA: nao estou a injectar html pois acho k é daqui que vem o undefined!!
  if (pageDirection == "next") {
    $newPicNext.append(newPicNextHtml);
    $newNextInfo.html(newNextInfo);
    $objTxt = $newNextInfo;
    xI = 10;
    classToAddpic = "exit-goingToRght";
  } else {
    $newPicPrev.append(newPicPrevHtml);
    $newPrevInfo.html(newPrevInfo);
    $objTxt = $newPrevInfo;
    xI = -10;
    classToAddpic = "exit-goingToLft";
  }

  //PIC: remove class filter when animated!
  $currentPic.removeClass("filter");
  $newPicNext.removeClass("filter");
  $newPicPrev.removeClass("filter");
  $(".page-main.page-current .modality-pic-wrapper").addClass(classToAddpic);
  //TEXT:
  $currentInfo.find(".vp-fullH").velocity(
    {
      opacity: 0,
    },
    {
      duration: 200,
      easing: "linear",
    }
  );

  $objTxt.css("opacity", 1);
  $objTxt.find(".instruct-info-lft").velocity(
    {
      opacity: [1, 0],
      translateZ: 0,
      translateX: [0, xI],
    },
    {
      duration: 450,
      easing: "ease-out",
      delay: 210,
    }
  );

  $objTxt.find(".instruct-info-rgt").velocity(
    {
      opacity: [1, 0],
      translateZ: 0,
      translateX: [0, 0],
    },
    {
      duration: 400,
      easing: "linear",
      delay: 700,
    }
  );

  $.doTimeout(1100, function () {
    $currentPage.remove();
    if (pageDirection === "next") {
      $prevContent.remove();
      $nextContent.removeClass("page-next");
      $nextContent.addClass("page-current").removeAttr("aria-hidden");
    } else {
      $nextContent.remove();
      $prevContent.removeClass("page-prev");
      $prevContent.addClass("page-current").removeAttr("aria-hidden");
    }
    var $newCurrentPage = $(".page-main.page-current"),
      $newToPreload = $(".page-current .preload");
    $newCurrentPage.after(
      '<div class="page-main page-prev" aria-hidden="true"></div>'
    );
    $newCurrentPage.after(
      '<div class="page-main page-next" aria-hidden="true"></div>'
    );
    $newCurrentPage.attr("style", "");

    // $("html,body").removeClass("js-fixed-all");
    // var nextContent = $newToPreload.find("a.next").attr('href'),
    //     prevContent = $newToPreload.find("a.prev").attr('href');
    var nextContent = $newToPreload.find("a.next").attr("data-ajaxUrl"),
      prevContent = $newToPreload.find("a.prev").attr("data-ajaxUrl");

    // NEED TO BE SURE THAT PRELOAD IS DONE!
    _isLoadedPageAjax = false;
    _global_allowNavigate = false;
    $("html,body").removeClass("js-fixed-all");
    loadPagesNextPrev(prevContent, nextContent);

    $.doTimeout(40, function () {
      if (_isLoadedPageAjax) {
        onStartPagewhenRefresh(false);
        $_body.removeClass("js-loading-page");
        return false;
      }
      return true;
    }); //END if preloading next/prev pages done?
  }); //end main Timeout
  return false;
} //////end Function loadPages TEAM

//***** =LOAD PAGES WITH FADE and COLOR (like modalities all) ** /
function loadPagesFade(newContent, $element, pageColor) {
  var $currentPage = $(".page-main.page-current"),
    $nextContent = $(".page-main.page-next"),
    $scaleWrap = $element.parents(".animScaleWrap");

  $("html,body").addClass("js-fixed-all");
  $_body.removeClass("js-no-ajax"); // I am using =ajax
  $_transitionFade.show();

  //START PRELOADING next page (if is in cache i may not need preloading animation)
  var nextContent = newContent,
    prevContent = null;
  _isLoadedPageAjax = false;
  loadPagesNextPrev(prevContent, newContent);

  //** check if content is on cache
  var timeToLoad = 0;

  $.doTimeout("isInCache", 10, function () {
    timeToLoad += 10;
    if (_isLoadedPageAjax) {
      timeToLoad = null;
      return false;
    }

    if (_isLoadedPageAjax == false && timeToLoad >= 160) {
      // I nead load animation (was 160)
      loadingLogoAnim($_transitionFade, "show");
      timeToLoad = null;
      return false;
    }
    return true;
  });

  //ANIMATION
  var vProperty = obgProperties($element, $scaleWrap);
  $_transitionFade.addClass(pageColor);
  //Animate clip Rect
  $_transitionFade.velocity(
    {
      clipTop: [0, vProperty.T],
      clipRight: [_globalViewportW, vProperty.R],
      clipBottom: [_globalViewportH, vProperty.B],
      clipLeft: [0, vProperty.L],
    },
    {
      duration: 300,
      easing: [0.91, 0.01, 0.6, 0.99],
      mobileHA: true,
      delay: 160,
    }
  );

  $.doTimeout(170, function () {
    $element.parent().css("opacity", "0");
  });
  //animate scale wraper page
  var forPageScaleX = vProperty.Cx,
    forPageScaleY = vProperty.CyRelative,
    forExceptionX = verge.rectangle($scaleWrap).left + 40;
  //exception for the 1st column
  if (vProperty.L <= forExceptionX) {
    forPageScaleX = vProperty.L;
  }
  $scaleWrap.css(
    "transform-origin",
    "" + forPageScaleX + "px " + forPageScaleY + "px 0px"
  );

  $scaleWrap.velocity(
    {
      scale: [2.4, 1],
    },
    {
      duration: 400,
      easing: [0.91, 0.01, 0.6, 0.99],
      mobileHA: true,
      delay: 170,
      complete: function () {
        animationLoadPagesFadeDone();
        window.scroll(0, 0); //reset scroll
      },
    }
  );

  function animationLoadPagesFadeDone() {
    $.doTimeout(50, function () {
      if (_isLoadedPageAjax) {
        clearPagesAfterloading(0); // calls onStartPagewhenRefresh
        classesForNavigation(); //logo and arrows navigation
        manageBodyClasses();
        $_transitionFade.velocity(
          {
            opacity: [0, 1],
          },
          {
            duration: 300,
            easing: "ease-out",
            mobileHA: true,
            delay: 50,
            complete: function () {
              $("html,body").removeClass("js-fixed-all");
              $_transitionFade.attr("style", "").attr("class", "");
              loadingLogoAnim($_transitionFade, "hide");
            },
          }
        );
        return false;
      }
      return true;
    });
  } //End function animation page loaded

  return false;
} //////end function load content width FADE

//***** =LOAD PAGES WITH circle and COLOR (like =home modalities) ** /
function loadPagesCircle(newContent, $element, pageColor) {
  var $currentPage = $(".page-main.page-current"),
    $nextContent = $(".page-main.page-next"),
    $scaleWrap = $element.parents(".list-modal-home"),
    $loadingCircle = $(".transition-circle-loading");

  $("html,body").addClass("js-fixed-all");
  $_body.removeClass("js-no-ajax"); // I am using =ajax
  $_transitionCircle.show();

  //START PRELOADING next page (if is in cache i may not need preloading animation)
  var nextContent = newContent,
    prevContent = null;
  _isLoadedPageAjax = false;
  loadPagesNextPrev(prevContent, newContent);

  //** check if content is on cache
  var timeToLoad = 0;

  $.doTimeout("isInCache", 10, function () {
    timeToLoad += 10;
    if (_isLoadedPageAjax) {
      timeToLoad = null;
      return false;
    }

    if (_isLoadedPageAjax == false && timeToLoad >= 1000) {
      // I nead load animation if takes more then 1s
      loadingLogoAnim($loadingCircle, "show");
      timeToLoad = null;
      return false;
    }
    return true;
  });

  //ANIMATION
  var vProperty = obgProperties($element, $scaleWrap);
  $_transitionCircle.addClass(pageColor);

  $_transitionCircle.css({ left: vProperty.Cx, top: vProperty.Cy });
  var maxValue = _globalViewportW;
  if (_globalViewportW <= _globalViewportH) maxValue = _globalViewportH;
  var toScale = Math.round((2 * maxValue) / 200);

  $element.parent().addClass("fadeout");
  $_transitionCircle.velocity(
    {
      scale: [toScale, 0.1],
    },
    {
      duration: 300,
      easing: [0.91, 0.01, 0.6, 0.99],
      mobileHA: true,
      delay: 50,
    }
  );

  //animate scale wraper page
  var forPageScaleX = vProperty.Cx,
    forPageScaleY = vProperty.CyRelative;
  $scaleWrap.css(
    "transform-origin",
    "" + forPageScaleX + "px " + forPageScaleY + "px 0px"
  );

  $scaleWrap.velocity(
    {
      scale: [2.4, 1],
    },
    {
      duration: 300,
      easing: [0.91, 0.01, 0.6, 0.99],
      mobileHA: true,
      delay: 55,
      complete: function () {
        animationLoadPagesCircleDone();
        window.scroll(0, 0); //reset scroll
      },
    }
  );

  function animationLoadPagesCircleDone() {
    $.doTimeout(50, function () {
      if (_isLoadedPageAjax) {
        clearPagesAfterloading(0); // calls onStartPagewhenRefresh
        classesForNavigation(); //logo and arrows navigation
        manageBodyClasses();
        $loadingCircle.addClass("fadeout");
        $_transitionCircle.velocity(
          {
            opacity: [0, 1],
          },
          {
            duration: 300,
            easing: "ease-out",
            mobileHA: true,
            delay: 50,
            complete: function () {
              $("html,body").removeClass("js-fixed-all");
              $_transitionCircle.attr("style", "").attr("class", "");
              loadingLogoAnim($loadingCircle, "hide");
              $loadingCircle.removeClass("fadeout").hide();
            },
          }
        );
        return false;
      }
      return true;
    });
  } //End function animation page loaded

  return false;
} //////end function load content width CIRCLE

// Get getBoundingClientRect
function obgProperties($element, $container) {
  var vRect = new Object(),
    Top = verge.rectangle($element).top,
    Right = verge.rectangle($element).right,
    Bottom = verge.rectangle($element).bottom,
    Left = verge.rectangle($element).left,
    Width = Right - Left,
    Height = Bottom - Top,
    CenterX = Left + Width / 2,
    CenterY = Top + Height / 2,
    containerTop = verge.rectangle($container).top,
    elementRelativTop = Math.abs(containerTop - Top),
    elementCenterYrelative = elementRelativTop + Height / 2;
  vRect = {
    T: Math.round(Top),
    R: Math.round(Right),
    B: Math.round(Bottom),
    L: Math.round(Left),
    W: Math.round(Width),
    H: Math.round(Height),
    Cx: Math.round(CenterX),
    Cy: Math.round(CenterY),
    CyRelative: Math.round(elementCenterYrelative),
  };
  return vRect;
}

//***** =LOAD PAGES SAME PAGE HEADER (like =GINASIOS) ** /
function loadPagesSame(newContent) {
  var $currentPage = $(".page-main.page-current"),
    $nextContent = $(".page-main.page-next");

  $("html,body").addClass("js-fixed-all");
  $_body.removeClass("js-no-ajax");
  $_body.addClass("js-loading-page");

  //START PRELOADING next page (if is in cache i may not need preloading animation)
  var nextContent = newContent,
    prevContent = null;
  _isLoadedPageAjax = false;
  loadPagesNextPrev(prevContent, newContent);
  // I need time to end animation
  $.doTimeout(400, function () {
    $.doTimeout(50, function () {
      if (_isLoadedPageAjax) {
        clearPagesAfterloading(0); // calls onStartPagewhenRefresh
        manageBodyClasses();
        $("html,body").removeClass("js-fixed-all");
        $_body.removeClass("js-loading-page");
        //=ALEX: width video remove auto scroll.
        // $.doTimeout(700, function() {
        //    var $toGoHere = $(".content-main-wrapper");
        //    goTo( $toGoHere, 900, [0.7,0,0.3,1], -300);
        // })
        return false;
      }
      return true;
    }); //end timeout
  });
} //////end function load content same header (ginasios)

/*-------------------------------------------------------------------------------------------
    =TRANSITIONS pages animation
--------------------------------------------------------------------------------------------*/
function transitionPagesAnimOut(direction) {
  var $currentPage = $(".page-main.page-current"), // can't be global
    xTransI,
    xTransF,
    xPageI,
    xPageF,
    pathStart;
  var yTransI = 0,
    yTransF = 0,
    yPageI = 0,
    yPageF = 0;

  switch (direction) {
    case "next":
      (xTransI = _globalViewportW - 200), (xTransF = 0);
      (xPageI = 0), (xPageF = -_globalViewportW + 200);
      pathStart = _transitionPaths.startNext;
      break;
    case "prev":
      (xTransI = -_globalViewportW + 200), (xTransF = 0);
      (xPageI = 0), (xPageF = _globalViewportW - 200);
      pathStart = _transitionPaths.startPrev;
      break;
    default:
      (xTransI = 0), (xTransF = 0), (xPageI = 0), (xPageF = 0);
      (yTransI = -_globalViewportH + 200), (yTransF = 0);
      (yPageI = 0), (yPageF = _globalViewportH - 200);
      pathStart = _transitionPaths.startUpDown;
      break;
  }

  $_transitionPathSVG.attr("d", pathStart);

  $_transition.velocity(
    {
      translateZ: 0,
      translateY: [yTransF, yTransI],
      translateX: [xTransF, xTransI],
    },
    {
      duration: 600,
      easing: [0.27, 0.54, 0.6, 0.99],
      mobileHA: true,
      delay: 0,
      complete: function () {
        classesForNavigation(); //logo and arrows navigation
        window.scroll(0, 0); //reset scroll
      },
    }
  );

  $currentPage.velocity(
    {
      translateZ: 0,
      translateY: [yPageF, yPageI],
      translateX: [xPageF, xPageI],
    },
    {
      duration: 700,
      easing: [0.91, 0.01, 0.6, 0.99],
      delay: 100,
      mobileHA: true,
    }
  );

  _transitionPathSVG.stop().animate(
    {
      path: _transitionPaths.full,
    },
    700,
    mina.easeout
  );
} //end transition page anim OUT

function transitionPagesAnimIn(direction) {
  var $nextPage = $(".page-main.page-next"), // can't be global
    xTransI,
    xTransF,
    xPageI,
    xPageF,
    pathEnd,
    delay = 810;
  var yTransI = 0,
    yTransF = 0,
    yPageI = 0,
    yPageF = 0;

  switch (direction) {
    case "next":
      $nextPage = $(".page-main.page-next");
      pathEnd = _transitionPaths.endNext;
      (xTransI = 0), (xTransF = -_globalViewportW);
      (xPageI = _globalViewportW - 200), (xPageF = 0);
      //delay on text
      break;
    case "prev":
      $nextPage = $(".page-main.page-prev");
      pathEnd = _transitionPaths.endPrev;
      (xTransI = 0), (xTransF = _globalViewportW);
      (xPageI = -_globalViewportW + 200), (xPageF = 0);
      break;
    default:
      $nextPage = $(".page-main.page-next");
      pathEnd = _transitionPaths.endUpDown;
      (xTransI = 0), (xTransF = 0), (xPageI = 0), (xPageF = 0);
      (yTransI = 0), (yTransF = _globalViewportH);
      (yPageI = -_globalViewportH + 200), (yPageF = 0);
      break;
  }

  $.doTimeout(delay, function () {
    $_transition.velocity(
      {
        translateZ: 0,
        translateY: [yTransF, yTransI],
        translateX: [xTransF, xTransI],
      },
      {
        duration: 900,
        easing: [0.91, 0.01, 0.6, 0.99],
        delay: 0,
        complete: function () {
          $_transition.hide();
          loadingLogoAnim($_transition, "hide");
        },
      }
    );

    $nextPage.velocity(
      {
        translateZ: 0,
        translateY: [yPageF, yPageI],
        translateX: [xPageF, xPageI],
      },
      {
        duration: 900,
        easing: [0.91, 0.01, 0.6, 0.99],
        delay: 0,
        complete: function () {
          $("html,body").removeClass("js-fixed-all");
          $nextPage.attr(
            "style",
            ""
          ); /* NEED if I have fixed elements on chrome */
        },
      }
    );
  }); //end timeout
} //end transition page anim IN / ENTRADA

/**-------------------------------------------------------------------------------------------
    =Load NEXT PREV Pages on background =CLEAR content
--------------------------------------------------------------------------------------------*/
function loadPagesNextPrev(prevContent, nextContent) {
  var $prevContent = $(".page-main.page-prev"),
    $nextContent = $(".page-main.page-next"); // can't be global

  $nextContent.load(
    nextContent + " .page-toload",
    function (response, status, xhr) {
      var $this = $(this);
      $(".page-main.page-next .preload").imagesLoaded(function (
        $images,
        $proper,
        $broken
      ) {
        var fPreload = $(this).imagesLoaded();
        fPreload.always(function () {
          if (!$_body.hasClass("mobile")) {
            $_newLoadImage = $(".page-main.page-next .animate-sprite");
          }
          $(".page-main.page-next .preload").find("picture").remove(); // I need the links
          _isLoadedPageAjax = true;
        });
      }); // end Images loaded
    }
  ); // end nextContent

  if (!prevContent) return;
  $prevContent.load(
    prevContent + " .page-toload",
    function (response, status, xhr) {
      var $this = $(this);
    }
  ); // end prevContent
} //////end function load next prev content

function clearPagesAfterloading(delay) {
  var $currentPage = $(".page-main.page-current"),
    $nextContent = $(".page-main.page-next"),
    $prevContent = $(".page-main.page-prev"); // can't be global

  $.doTimeout(delay, function () {
    $currentPage.remove();
    $prevContent.remove();
    $nextContent.removeClass("page-next");
    $nextContent.addClass("page-current").removeAttr("aria-hidden");
    var $newCurrentPage = $(".page-main.page-current"),
      $newToPreload = $(".page-current .preload");
    $newCurrentPage.after(
      '<div class="page-main page-prev" aria-hidden="true"></div>'
    );
    $newCurrentPage.after(
      '<div class="page-main page-next" aria-hidden="true"></div>'
    );
    $newCurrentPage.attr("style", "");
    onStartPagewhenRefresh(false);
    // If I go to a next/prev section of the site
    if ($newToPreload.hasClass("preload-more")) {
      // var nextContent = $newToPreload.find("a.next").attr('href'),
      //     prevContent = $newToPreload.find("a.prev").attr('href');
      var nextContent = $newToPreload.find("a.next").attr("data-ajaxUrl"),
        prevContent = $newToPreload.find("a.prev").attr("data-ajaxUrl");

      loadPagesNextPrev(prevContent, nextContent);
    } //end if
  }); //end do timeout
} //end function

/*-------------------------------------------------------------------------------------------
    =Loading pages helpers
--------------------------------------------------------------------------------------------*/
function fcheckWhereTo(newContent) {
  var nextPageAlreadyLoaded = false;
  var $links = $(".page-main.page-current").find(".preload-more a"); // nao pode ser global

  $links.each(function () {
    var compareURL = $(this).attr("href");
    if (compareURL === newContent) {
      nextPageAlreadyLoaded = true;
      return nextPageAlreadyLoaded;
    }
  });
  return nextPageAlreadyLoaded;
} //end function

//** loading P animation. Show and delete
function loadingLogoAnim($element, action) {
  var $container = $element.find(".loadigPage-anim"),
    $image = $element.find(".animate-sprite"),
    index = $image.attr("data-n");
  if ($container.length === 0) $container = $element;

  if (action == "show") {
    $container.show();
    $image.animateSpriteIMG({
      widthFrame: 225,
      heightFrame: 209,
      totalFrames: _loadingObjs[index].nframes,
      totalRow: _loadingObjs[index].nrow,
      totalColumn: _loadingObjs[index].ncolumn,
      speed: 30,
      loop: true,
    });
  } else {
    $image.destroy();
    $_loadigPageHolder.empty().append($_newLoadImage); //=LOADING animation - change image
    //$container.hide();
  }
} //end function

/*-------------------------------------------------------------------------------------------
    =CLASSES FOR NAVIGATION =for modalities, team and id
--------------------------------------------------------------------------------------------*/
function classesForNavigation() {
  $(".page-main.page-next .home").length
    ? $_body.addClass("home")
    : $_body.removeClass("home");
  $(".page-main.page-next .has-arrows").length
    ? $_body.addClass("has-arrows")
    : $_body.removeClass("has-arrows");
} //end function

/********************************************************************************************
 **                                                                                       **
     =START EACH PAGE - refresh or ajax
 **                                                                                       **
*********************************************************************************************/
function onStartPagewhenRefresh(byRefresh) {
  if (byRefresh) {
    // :BUG CHROME: even wit this is not scrolling top is some section, needed hack after preloading with animate
    window.scrollTo(0, 0);
    $("html,body").scrollTop(0);
    //
    $("html,body").addClass("js-fixed-all");
    $_transition.addClass("js-byrefresh");
    $_body.removeClass("js-byrefresh");

    manageBodyClasses();

    if ($(".promo-link").hasClass("active")) {
      setTimeout(function () {
        if (!Cookies.get("promo-modal-closed")) $(".promo-link").click();
      }, 20000);
    }

    if (!$_body.hasClass("mobile")) {
      loadingLogoAnim($_transition, "show");
      //when I want mupliple preloads (=modalidades, =Team)
      // next and prev content is always loaded by ajax so no need of attr('href')
      if ($_toPreload.hasClass("preload-more")) {
        // var nextContent = $_toPreload.find("a.next").attr('href'),
        //     prevContent = $_toPreload.find("a.prev").attr('href');
        var nextContent = $_toPreload.find("a.next").attr("data-ajaxUrl"),
          prevContent = $_toPreload.find("a.prev").attr("data-ajaxUrl");
        loadPagesNextPrev(prevContent, nextContent);
      } //end if
    }
    // else {
    //   $(".loadigPage-mobile-anim").addClass("anim-go");
    // }

    $_toPreload.imagesLoaded(function ($images, $proper, $broken) {
      var fPreload = $(this).imagesLoaded();
      fPreload.always(function () {
        classesForNavigation(); //logo and arrows navigation
        if ($(".page-current .home").length) {
          $_body.addClass("home");
        } //home logo
        if ($(".page-current .has-arrows").length) {
          $_body.addClass("has-arrows");
        } //arrows
        // ** =LOADING animation - change image (if not mobile!)
        if (!$_body.hasClass("mobile")) {
          $_newLoadImage = $(".page-main.page-current .animate-sprite");
        }
        $_toPreload.find("img").remove();

        $("html,body").animate(
          {
            scrollTop: 0,
          },
          100
        ); // :BUG CHROME: need anim or timeout to force scroll top ( the value 100ms is arbitrary, less was not working and also must be after preloading)

        $_transition.velocity(
          {
            opacity: 0,
          },
          600,
          function () {
            $("html,body").removeClass("js-fixed-all");
            $_transition.removeClass("js-byrefresh").hide();
            if (!$_body.hasClass("mobile")) {
              loadingLogoAnim($_transition, "hide");
            }
            // else {
            //    $(".loadigPage-mobile-anim").removeClass("anim-go").hide();
            // }
            onStartPage();
          }
        );
      }); //end always
    }); //end preload images
  } else {
    onStartPage();
  }
} //////end function

/*-------------------------------------------------------------------------------------------
    =START EACH PAGE - call of functions and events
--------------------------------------------------------------------------------------------*/
function onStartPage() {
  // ** =ALLOW user load other pages
  _global_allowNavigate = true;

  /*Refresh global values*/
  $_pageHeader = $(".page-current .page-header");
  $_pageFooter = $(".page-current .page-footer");
  $_pageContent = $(".page-current .page-content");
  $_pageToLoad = $(".page-current .page-toload");

  // **=POLYFILL ASYNC
  callAsyncFunctions();

  if (_currentView) _currentView.kill();

  switch ($_pageToLoad.attr("data-bodyClass").split(" ")[0]) {
    case "home":
      _currentView = homePage();
      _currentView.init();
      break;

    case "horarios":
      _currentView = horariosPage();
      _currentView.init();
      break;

    case "ginasios-all":
      _currentView = ginasiosPage();
      _currentView.init();
      break;

    case "ginasios":
      _currentView = ginasioPage();
      _currentView.init();
      break;

    case "modalidades-all":
      _currentView = modalidadesPage();
      _currentView.init();
      break;

    case "modalidades":
      _currentView = modalidadesSinglePage();
      _currentView.init();
      break;

    case "team-all":
      _currentView = teamPage();
      _currentView.init();
      break;

    case "team-member":
      _currentView = teamSinglePage();
      _currentView.init();
      break;

    case "corporate":
      _currentView = corporatePage();
      _currentView.init();
      break;

    case "nutricao-all":
      _currentView = blogAllPage();
      _currentView.init();
      break;

    case "health-all":
      _currentView = blogAllPage();
      _currentView.init();
      break;

    case "nutricao-single":
      _currentView = blogSinglePage();
      _currentView.init();
      break;

    case "health-single":
      _currentView = blogSinglePage();
      _currentView.init();
      break;

    case "recruta":
      _currentView = recrutaPage();
      _currentView.init();
      break;

    case "inscricoes":
      _currentView = inscricoesPage();
      _currentView.init();
      break;

    case "page-404":
      _currentView = page404Page();
      _currentView.init();
      break;
  }

  //** =LOADING animation - change image (if not mobile && new Image value when loading next section)
  // NOTE: can't be here or i see the P animation change ate the end of the ploading > put in loadingLogoAnim()!!
  // if (!$_body.hasClass('mobile')) {
  //     $_loadigPageHolder.empty().append($_newLoadImage);
  // }

  // ** =MODALIDADES/TEAM = ARROWNS NAVIGATIONS
  $(".page-current .has-arrows").length
    ? (do_navArrows = true)
    : (do_navArrows = false);
  fArrowsNav(do_navArrows);

  // // ** =FOOTER 2c
  $(".page-current .footer-2c").length
    ? (do_footer2c = true)
    : (do_footer2c = false);
  fFooter2c(do_footer2c);

  // //** ANIMATION intro blocks ( ALEX: apagar???? na nova versao? )
  $(".page-current .anim-wrap-DownUp").length
    ? (do_animDownUp = true)
    : (do_animDownUp = false);
  animBlockDownUp(do_animDownUp);

  // ** =URL com ancoras onload
  var hasHash = window.location.hash;
  if (hasHash != "") {
    var $toGoHere = $("" + hasHash + "");
    $.doTimeout(100, function () {
      goTo($toGoHere, 1000, [0.7, 0, 0.3, 1], 0);
    });
  }

  //** General events
  forForms();
  defaultBuroEvents();
} //////end function

/*******************************************************************************************
 **                                                                                       **
      =GENERAL FUNCTIONS AND PLUGINGS CONTROL
 **                                                                                       **
*********************************************************************************************/
/** =Global page values */
function calculateGlobalValues() {
  _globalViewportW = verge.viewportW();
  _globalViewportH = verge.viewportH();
  _globalHalfViewportW = _globalViewportW / 2;
  _globalHalfViewportH = _globalViewportH / 2;
  _globalOneFiveViewportH = _globalViewportH / 1.5;
}
/** =ASYNC PLUGINS (polyfill, etc) */
function callAsyncFunctions() {
  if (window.respimage) respimage();
  if (window.svg4everybody) svg4everybody();
}
/** =Random integer between min (included) and max (excluded) */
function getRandomInt(min, max) {
  return Math.floor(Math.random() * (max - min)) + min;
}

/* Detects page ratio NOTE: dont need, have the plugin that puts on html orientation_landscape and
portrait*/
// function viewportRatio(){
//     var vRatio = verge.aspect().toFixed(2);
//         ( vRatio <= 1) ? $_body.addClass("v-lowRatio") : $_body.removeClass("v-lowRatio");
// }

/*-------------------------------------------------------------------------------------------
=FORMS (needed to be repeated because of ajax and resize input)
--------------------------------------------------------------------------------------------*/
function forForms() {
  //** =Clear input on click
  $(
    "input[type=text], input[type=email], input.text, input.email, textarea"
  ).each(function () {
    var $this = $(this);
    if (!$this.hasClass("txtClear")) return;

    var defeito = this.defaultValue;
    $this.focus(function () {
      $this.parents("form").addClass("focus-on");
      if ($this.val() == defeito) {
        $this.val("");
      }
    });
    $this.blur(function () {
      $this.parents("form").removeClass("focus-on");
      if ($this.val() == "") {
        $this.val(defeito);
      }
      $("input.resizeIt").each(resizeInput); //exception if i have resize it
    });
  });
} //// end Form forms

//=input fields
function resizeInput() {
  // if ($_body.hasClass("mobile")) return false;
  var $this = $(this);
  $this.attr("size", $this.val().length);
}

/*-----------------------------------------------------------------------
 =FORMS ANIMATION LOADING
 NOTE: changed to giv error on element and not parent
-----------------------------------------------------------------------*/
function formAnimLoading($element) {
  $element.addClass("loading");
  //give time mouseOut animation
  $.doTimeout(550, function () {
    $element.addClass("go");
  });

  // IF OK SENT
  $.doTimeout(3400, function () {
    $element.addClass("go-ok");
  });
  $.doTimeout(5500, function () {
    // $element.addClass("go-ok-done");
    $element.addClass("go-reset");
  });
  //end all
  $.doTimeout(6500, function () {
    $element.removeClass("loading go go-ok go-reset");
  });
}

//New form animation with 1 more exception — Error State
function formAnimLoadingNEW($element, state) {
  if (state == "loading") {
    //Anim send button -> Replace in function
    $element.addClass("loading");
    //give time mouseOut animation
    $.doTimeout(550, function () {
      $element.addClass("go");
    });
    $.doTimeout(2700, function () {
      $element.addClass("pause");
    });
  } else if (state == "sent-ok") {
    // IF OK SENT
    $.doTimeout(0, function () {
      $element.removeClass("pause");
      $element.addClass("go-ok");
    });
    $.doTimeout(2100, function () {
      // $element.addClass("go-ok-done");
      $element.addClass("go-reset");
    });
    //end all
    $.doTimeout(3100, function () {
      $element.removeClass("go go-ok go-reset loading enable");
      $element.parents("form").find("input:not([type='checkbox'])").val("");
      $element.parents("form").find("textarea").val("");
      $element
        .parents("form")
        .find(".js-file-name")
        .text(
          $element
            .parents("form")
            .find(".js-file-name")
            .attr("data-placeholder")
        )
        .attr("style", "");
      $element.parents("form").find(".rgpd-wrapper").removeClass("active");
      $element.parents("form").find(".radio-selection").removeClass("selected");
      $element
        .parents("form")
        .find("input[type='checkbox']")
        .removeAttr("checked");
    });
  } else if (state == "sent-error") {
    // // IF OK ERROR
    // $.doTimeout(0, function() {
    //     $element.removeClass('pause');
    // });
    // $.doTimeout(400, function() {
    //     $element.addClass("go-error")
    // });
    // $.doTimeout(2100, function() {
    //     // $element.addClass("go-ok-done");
    //     $element.addClass('go-reset');
    // });
    // //end all
    // $.doTimeout(3100, function() {
    //     $element.removeClass('go go-error go-reset loading');
    // });

    // IF OK SENT
    $.doTimeout(0, function () {
      $element.removeClass("pause");
      $element.addClass("go-error");
    });
    $.doTimeout(2100, function () {
      // $element.addClass("go-ok-done");
      $element.addClass("go-reset");
    });
    //end all
    $.doTimeout(3100, function () {
      $element.removeClass("go go-ok go-reset loading enable");
      $element.parents("form").find("input:not([type='checkbox'])").val("");
      $element.parents("form").find("textarea").val("");
      $element
        .parents("form")
        .find(".js-file-name")
        .text(
          $element
            .parents("form")
            .find(".js-file-name")
            .attr("data-placeholder")
        )
        .attr("style", "");
      $element.parents("form").find(".rgpd-wrapper").removeClass("active");
      $element.parents("form").find(".radio-selection").removeClass("selected");
      $element
        .parents("form")
        .find("input[type='checkbox']")
        .removeAttr("checked");
    });
  }
}

/*-----------------------------------------------------------------------
 =FORMS VALIDADTE
 NOTE: changed to giv error on element and not parent
-----------------------------------------------------------------------*/

function campainMonitorForm($form) {
  var cmform = document.createElement("form");

  cmform.setAttribute("method", "post");
  cmform.setAttribute("action", $form.attr("data-cmaction"));

  $form
    .find("input")
    .each(function () {
      var $this = $(this),
        cmname = $this.attr("data-cmname");

      if (cmname) {
        var i = document.createElement("input"); //input element, text

        if ($this.attr("data-cmemail") == "true")
          i.setAttribute("type", "email");
        else i.setAttribute("type", $this.attr("type"));

        i.setAttribute("name", cmname);
        i.setAttribute("value", $this.attr("value"));

        cmform.appendChild(i);
      }
    })
    .promise()
    .done(function () {
      var $cmform = $(cmform);

      $.ajax({
        data: $cmform.serialize(),
        type: "post",
        dataType: "jsonp",
        url: $cmform.attr("action"),
        success: function (data) {
          console.log("sent", data.Message);
        },
        error: function (data) {
          console.log("erro", data);
        },
      });
    });
}

function validateForm($form) {
  $form.find(".formMsg").hide();
  var error = 0;
  $form.find(".required").each(function () {
    var $this = $(this),
      defeito = this.defaultValue;
    error = check($this, defeito, error);
  }); //end each
  if (error > 0) {
    return false;
  } else {
    return true;
  }
} //end validate form

//functiom check inputs
function check($this, defeito, error) {
  //is an email
  if ($this.hasClass("email")) {
    // is this an email
    if (!validateEmail($this)) {
      error += 1;
      $this.parent().addClass("error");
      $this.addClass("error");
      return error;
    } else {
      $this.parent().removeClass("error");
      $this.removeClass("error");
      return error;
    }
  } //end if mail

  //is an phone
  if ($this.hasClass("phone")) {
    // is is a phone
    if (!validatePhone($this)) {
      error += 1;
      $this.parent().addClass("error");
      $this.addClass("error");
      return error;
    } else {
      $this.parent().removeClass("error");
      $this.removeClass("error");
      return error;
    }
  } //end if mail

  //is an birthdate
  if ($this.hasClass("birthdate")) {
    // is is a birthdate
    if (!validateBirthdate($this)) {
      error += 1;
      $this.parent().addClass("error");
      $this.addClass("error");
      return error;
    } else {
      $this.parent().removeClass("error");
      $this.removeClass("error");
      return error;
    }
  } //end if birthdate

  //is an number
  if ($this.hasClass("number")) {
    // is is a birthdate
    if (!validateNumbers($this)) {
      error += 1;
      $this.parent().addClass("error");
      $this.addClass("error");
      return error;
    } else {
      $this.parent().removeClass("error");
      $this.removeClass("error");
      return error;
    }
  } //end if number

  //is a number or email
  if ($this.hasClass("phonemail")) {
    // is is a phone
    if (validatePhone($this)) {
      $this.parent().removeClass("error");
      $this.removeClass("error");
      return error;
    } else if (validateEmail($this)) {
      $this.parent().removeClass("error");
      $this.removeClass("error");
      return error;
    } else {
      error += 1;
      $this.parent().addClass("error");
      $this.addClass("error");
      return error;
    }
  } //end

  // //is an radio
  // if ($this.hasClass("select")) { // is is a select

  //     if (!validateSelect($this)) {
  //         error += 1;
  //         $this.parent().addClass("erro");
  //         return error;
  //     } else {
  //         $this.parent().removeClass("erro");
  //         return error;
  //     }
  // } //end if radio

  //is an captcha
  if ($this.hasClass("cap")) {
    // is is an email
    if ($this.val() == "" || $this.val() == defeito) {
      $this.parent().children("span").addClass("on");
      error += 1;
      $this.parent().addClass("error");
      $this.parent().addClass("error");
      return error;
    } else {
      $this.parent().children("span").removeClass("on");
      $this.parent().removeClass("error");
      return error;
    }
  } //end if mail

  //not empty field
  if ($this.val() == "" || $this.val() == defeito) {
    error += 1;
    $this.parent().addClass("error");
    $this.addClass("error");
    return error;
  } else {
    $this.parent().removeClass("error");
    $this.removeClass("error");
    return error;
  } //end if
} //end function

//email validation
function validateEmail($this) {
  var a = $this.val();
  var filter =
    /^[a-zA-Z0-9]+[a-zA-Z0-9_.-]+[a-zA-Z0-9_-]+@[a-zA-Z0-9]+[a-zA-Z0-9.-]+[a-zA-Z0-9]+.[a-z]{2,4}$/;
  if (filter.test(a)) {
    return true;
  } else {
    return false;
  }
}
//Phone validation
function validatePhone($this) {
  var a = $this.val();
  var filter = /^[+]?[0-9 ]{6,}$/;
  if (filter.test(a)) {
    return true;
  } else {
    return false;
  }
}
//Birthdate validation
function validateBirthdate($this) {
  var a = $this.val();
  var filter =
    /^(((0[1-9]|[12]\d|3[01])\/(0[13578]|1[02])\/((19|[2-9]\d)\d{2}))|((0[1-9]|[12]\d|30)\/(0[13456789]|1[012])\/((19|[2-9]\d)\d{2}))|((0[1-9]|1\d|2[0-8])\/02\/((19|[2-9]\d)\d{2}))|(29\/02\/((1[6-9]|[2-9]\d)(0[48]|[2468][048]|[13579][26])|((16|[2468][048]|[3579][26])00))))$/g;
  if (filter.test(a)) {
    return true;
  } else {
    return false;
  }
}
//Numbers validation
function validateNumbers($this) {
  var a = $this.val();
  var filter = /[0-9 -()+]+$/;
  if (filter.test(a)) {
    return true;
  } else {
    return false;
  }
}
//Radio validation
function validateSelect($this) {
  $this.each(function () {
    var a = $this.attr("checked");
    if (a == "checked") {
      return true;
    } else {
      return false;
    }
  });
}

////ONLY TYPE NUMBERS and , .
function onlyNumber(event) {
  if (event.shiftKey) {
    event.preventDefault();
  }
  if (
    event.keyCode == 46 ||
    event.keyCode == 8 ||
    event.keyCode == 190 ||
    event.keyCode == 188
  ) {
  } else {
    if (event.keyCode < 95) {
      if (event.keyCode < 48 || event.keyCode > 57) {
        event.preventDefault();
      }
    } else {
      if (event.keyCode < 96 || event.keyCode > 105) {
        event.preventDefault();
      }
    }
  }
} //end function

/*******************************************************************************************
 ****                                                                                   ****
    =DOCUMENT =READY =START Document ready
 ****                                                                                   ****
*********************************************************************************************/
$(document).ready(function () {
  //** =Global objects
  ($_window = $(window)),
    ($_body = $("body")),
    ($_html = $("html")),
    ($_mainHeader = $("#header-main")),
    ($_mainHeaderSlide = $("#header-main .nav-sec-mobile")),
    ($_mainHeaderBg = $("#header-bg")),
    ($_btn_mainNav = $(".open-nav-main")),
    ($_mainNav = $("#nav-main")),
    ($_mainNavWrap = $("#nav-main-control")),
    ($_mainPage = $(".page-main")),
    ($_footer = $(".footer")),
    ($_currentPage = $(".page-main.page-current")),
    ($_btns_close_x = $(".page-current .btn-close .btn")),
    ($_toPreload = $(".preload")),
    ($_holderLoad = $("#holderLoad"));
  //** =global variables*/
  calculateGlobalValues();
  _global_allowNavigate = false; //When loading do not allow clicks by user ( onStartPage revers to true)
  _global_useViewPort = true;
  _currentView = null;

  _customScroll = null;

  $_pageHeader = $(".page-current .page-header");
  $_pageFooter = $(".page-current .page-footer");
  $_pageContent = $(".page-current .page-content");
  $_pageToLoad = $(".page-current .page-toload");

  //_audioFadeInterval = null;

  _globalCalendarColor_lagrimas = "rgba(0,0,0,0.09)";
  _globalCalendarColor_celas = "rgba(255,255,255,.10)";

  //** =audio Global
  $_audioBtn = $(".mute-button");
  _audioGlobal = true;
  _audiAllM = document.getElementById("soundfx_allM");
  _audioSingleM = document.getElementById("soundfx_singleM");
  $homeVideo_ = $("#home-video")[0];
  $homeAudio_ = $("#soundfx_phive")[0];
  _loop_404 = null;

  //** =Global Page Transitions (morph with snapsvg)
  ($_transitionFade = $("#transition-fade")),
    ($_transitionCircle = $("#transition-circle")),
    ($_transition = $("#transition")),
    ($_transitionBg = $("#transition-bg")),
    ($_transitionPathSVG = $(".transition-svg path"));
  var transitionSVG = $(".transition-svg")[0];
  _transitionSnapSVG = Snap(transitionSVG);
  (_transitionPathSVG = _transitionSnapSVG.select("path")),
    (_transitionPaths = {
      full: "M800,600H0V0h800V600z",

      startNext: "M800,600h-2.333L435,0h365V600z",
      middleNext: "M509.5,600H0V0h676.5L509.5,600z",
      endNext: "M7.5,600H0V0h179.5L7.5,600z",
      // endNext : "M11.5,600H0V0h192.5L11.5,600z",

      startPrev: "M3.5,600H0V0h308.5L3.5,600z",
      endPrev: "M800.5,600h-8L640.5,0H800L800.5,600z",
      //endPrev : "M800,600h-2.333L435,0h365V600z",

      startUpDown: "M800,3.5L0,258.5V0h800V3.5z",
      endUpDown: "M800,600H0v-4.832l800,1.333V600z",
    });

  //** =Global LOADING Animations
  $_loadigPageHolder = $(".loadingPage-anim-random");
  $_newLoadImage = null;
  _loadingObjs = new Array();
  _loadingObjs[0] = {
    nframes: 24,
    nrow: 2,
    ncolumn: 13,
  };
  _loadingObjs[1] = {
    nframes: 24,
    nrow: 2,
    ncolumn: 13,
  };
  _loadingObjs[2] = {
    nframes: 39,
    nrow: 3,
    ncolumn: 13,
  };
  _loadingObjs[3] = {
    nframes: 39,
    nrow: 3,
    ncolumn: 13,
  };
  _loadingObjs[4] = {
    nframes: 52,
    nrow: 4,
    ncolumn: 13,
  };
  _loadingObjs[5] = {
    nframes: 52,
    nrow: 4,
    ncolumn: 13,
  };

  _rAF_loop =
    window.requestAnimationFrame ||
    window.webkitRequestAnimationFrame ||
    window.mozRequestAnimationFrame ||
    window.msRequestAnimationFrame ||
    window.oRequestAnimationFrame ||
    // IE Fallback, you can even fallback to onscroll
    function (callback) {
      window.setTimeout(callback, 1000 / 60);
    };
  _cancel_rAF_loop =
    window.cancelAnimationFrame || window.mozCancelAnimationFrame;

  _raf_loop_id = null;
  _raf_main_id = null;

  //** =START PAGES and PLUGINS
  onStartPagewhenRefresh(true); // this one calls onStartPageForAjax() + add class for lightbox mobile + header for mobile
  callAsyncFunctions();
  mainNavigation();
  newsletterForm();
  promosForm();
  //viewportRatio();
  startModal();

  //** =SAFARI ios > back-forward cache
  if ($_html.hasClass("ios")) {
    $_window.on("pageshow", function (event) {
      if (event.originalEvent.persisted) {
        window.location.reload();
      }
    });
  }

  /** -----------------------------------------------------------------------------------------
  =LOAD SECTIONS - triger events =CLICK to LOAD PAGE
  body class="js-no-ajax ismobile" > inserida via php == no nosso caso a mobile
  --------------------------------------------------------------------------------------------*/
  var domainSite = window.location.host,
    mainTitle = " | Phive — Health & fitness centers",
    homeTitle = $("h1 a").attr("data-title-home");
  $_linkInternal = $('a[href*="' + domainSite + '"]');

  //** =LOAD SECTIONS =EVENTS

  $(document).on("click", ' a[href*="' + domainSite + '"] ', function () {
    var $this = $(this);

    // **ALLOW user load other pages only after page is loaded ( onStartPage )
    if (!_global_allowNavigate) return false;
    _global_allowNavigate = false;
    // exit
    if ($_body.hasClass("mobile")) {
      $_transition.addClass("js-byrefresh").attr("style", "");
      window.location.href = $(this).attr("href"); //need like this because of swipe But i loose back history!!
      return true;
    }
    if ($this.hasClass("modal") || $this.hasClass("js-no-transPage")) return;
    //audio
    var thisAudio = $this.attr("data-audio");
    if (thisAudio === "off") audioMuteAll();
    //
    var thisHref = $this.attr("href"), // need for history
      thisHrefAjax = $this.attr("data-ajaxUrl"),
      thisTitle = $this.attr("data-title"),
      pageDirection = $this.attr("data-direction"), //next, prev (do no use rel because semantic/google)
      pageColor = $this.attr("data-bgcolor"), // transition pages that I need bg Color
      pageTransition = $this.attr("data-pageTrans"), // if not default is with fade or flip
      forHistory = thisHref,
      newContent = thisHrefAjax,
      forTitle = thisTitle + mainTitle;

    //home page
    if (!thisTitle) {
      forTitle = homeTitle;
    }
    var hasHash = $this.prop("hash");
    if ($(".page-current .home").length && $this.hasClass("js-scroll")) {
      goTo($("" + hasHash + ""), 2200, "easeInOutCubic");
      _global_allowNavigate = true;
      return false;
    }
    //for history
    history.pushState({}, forTitle, forHistory);
    // for title
    $("head title").html(forTitle);
    ga("send", "pageview", window.location.pathname); //analytics

    //If I havepages transitions that are not default (all modalities and between modalities)
    // =ALEX: se a team tiver uma especifica mete-la aqui!! e o default ser para outros sitios

    if (pageTransition) {
      if (pageTransition == "flip" || pageTransition == "flipTeam") {
        if (Modernizr.mq("(max-width: 920px)")) return true;
        //HERE the direction i want (rel here has semantic meaning)
        pageDirection = $this.attr("rel");
        //Do I need to scroll top first?
        var scrollVal = verge.scrollY(),
          speed = null,
          curve = null;
        if (scrollVal <= 30) {
          if (pageTransition == "flip") {
            loadPagesFlip(newContent, $this, pageDirection, pageColor); //modalidades
          } else {
            loadPagesFlipTeam(newContent, $this, pageDirection); //team
          }
          return false;
        }
        if (scrollVal <= 300) {
          speed = 300;
          curve = "easeInOutQuart";
        } else {
          speed = 600;
          curve = "easeInOutQuint";
        }
        goTo($_body, speed, curve, null);
        $.doTimeout(speed, function () {
          if (pageTransition == "flip") {
            loadPagesFlip(newContent, $this, pageDirection, pageColor); //modalidades
          } else {
            loadPagesFlipTeam(newContent, $this, pageDirection); //team
          }
        });
        return false;
      } else if (pageTransition == "circle") {
        $this.addClass("clicked");
        loadPagesCircle(newContent, $this, pageColor);
      } else if (pageTransition == "fade") {
        $this.addClass("clicked");
        loadPagesFade(newContent, $this, pageColor);
      }
      //else if ( pageTransition == "samePage" ) {
      //     return false;
      //     var scrollVal = verge.scrollY(),
      //         speed = null,
      //         curve = null;

      //         if (scrollVal <= 300) {
      //           speed = 300;
      //           curve = "easeInOutQuart";
      //         } else {
      //           speed = 600;
      //           curve = "easeInOutQuint";
      //         }
      //         goTo($_body, speed, curve, null);
      //         $.doTimeout(speed, function() {
      //             loadPagesSame(newContent);
      //         })

      // }
      return false;
    }
    //default transitions (right, left , top down)
    // note can't always use rel=next, prev Because has semantic meaning!
    if (!pageDirection) {
      pageDirection = "topDown";
    }
    loadPages(newContent, pageDirection);
    return false;
  }); //end click

  /// =HISTORY
  //  note: Chrome and Safari will fire a popstate event when the page loads but Firefox doesnt. When your page loads, it might have a non-null state object and the page will receive an onload event, but no popstate event. (window.history.state; on refresh page)
  if (window.addEventListener) {
    window.addEventListener("popstate", function (e) {
      if ($_html.hasClass("mobile")) return false;
      if (!e.state && $_html.hasClass("safari")) return false; //webkit bug
      if (!e.state) return false; //webkit bug on pagespeed, without this can+t go to the page but we lose first history
      $_body.addClass("js-no-ajax");
      $("html,body").addClass("js-byrefresh");
      window.location = window.location; // reload page for this new adress!
      return false;
    });
  } //endif: does not excute for <= IE8

  /*-------------------------------------------------------------------------------------------
    =EVENTS General
    --------------------------------------------------------------------------------------------*/
  var $btn_close_mainNav = $("#header-main .btn-close"),
    $btn_arrow_square = $(".btn-arrow");

  $(".js-btn-mainNav-close, #header-bg").on(
    "click touchstart",
    function (event) {
      event.stopImmediatePropagation();

      $_btn_mainNav.click();
      $btn_close_mainNav.removeClass("over");
      //mainNav_controll(true);
      // $("#nav-main").hide();
      event.stopPropagation();
      event.preventDefault();
      return false;
    }
  );

  $("#header-bg").on("mouseenter", function (event) {
    $btn_close_mainNav.addClass("over");
  });
  $("#header-bg").on("mouseleave", function (event) {
    $btn_close_mainNav.removeClass("over");
  });

  $("#nav-main-list a, #nav-main-sec a").on("click", function (event) {
    $_btn_mainNav.click();

    // if ( ! $_body.hasClass('mobile') ){

    // } else {
    //   $("html,body").removeClass("open-nav");
    // }
  });

  /* *** =BTN SQUARES with ARROWS */
  $(document).on("mouseenter", ".btn-arrow", function (event) {
    var $this = $(this);
    if ($this.hasClass("loading")) return false;
    $this.doTimeout("fOver", 100, "addClass", "over");
    event.preventDefault();
  }); //end click

  $(document).on("mouseleave", ".btn-arrow", function (event) {
    var $this = $(this);
    if ($this.hasClass("loading")) return false;
    $this.doTimeout("fOver", 0, "removeClass", "over");
    event.preventDefault();
  });

  $(document).on("click", ".btn-arrow", function (event) {
    if ($_body.hasClass("mobile")) return true;
    var $this = $(this);
    $this.doTimeout("fOver", 0, "removeClass", "over");
    //event.preventDefault();
  });

  $(document).on("mouseleave", ".underline-anim", function (event) {
    var $this = $(this);
    if ($this.hasClass("hover-out")) return;
    $this.addClass("hover-out");
    $this.doTimeout("fOverUnderline", 500, "removeClass", "hover-out");
    event.preventDefault();
  });

  /* *** =BTN WATCH MODALITY */
  $(document).on("mouseenter", ".watch-modality", function (event) {
    var $this = $(this);
    if ($this.hasClass("loading")) return false;
    $this.doTimeout("fOver", 100, "addClass", "over");
    event.preventDefault();
  }); //end click

  $(document).on("mouseleave", ".watch-modality", function (event) {
    var $this = $(this);
    if ($this.hasClass("loading")) return false;
    $this.doTimeout("fOver", 0, "removeClass", "over");
    event.preventDefault();
  });

  $(document).on("click", ".watch-modality", function (event) {
    if ($_body.hasClass("mobile")) return true;
    var $this = $(this);
    $this.doTimeout("fOver", 0, "removeClass", "over");
    event.preventDefault();
  });

  /**** =main nav svg */
  $(document).on("mouseenter", "#nav-main-list a", function (event) {
    var $this = $(this);
    $this.doTimeout("fOver", 50, "addClass", "over");
    event.preventDefault();
  }); //end click

  $(document).on("mouseleave", "#nav-main-list a", function (event) {
    var $this = $(this);
    $this.doTimeout("fOver", 0, "removeClass", "over");
    event.preventDefault();
  });

  /**** =AUDIO BTN */
  $_audioBtn.on("click", function (event) {
    var $this = $(this);
    $this.hasClass("sound-on")
      ? $this.removeClass("sound-on")
      : $this.addClass("sound-on");

    if (_audioGlobal) {
      _audioGlobal = false;
      audioMuteAll();
    } else {
      _audioGlobal = true;
      audioUnMute();
    }
    event.preventDefault();
  });

  /*-------------------------------------------------------------------------------------------
    =KEYS and CLICK
    --------------------------------------------------------------------------------------------*/
  $(document).on("keydown", function (event) {
    switch (event.which) {
      case 40: //down
        //return false;
        break;
      case 38: //up
        // return false;
        break;
      case 39: //next
        if ($(".page-current .has-arrows").length) {
          $("#nav-main-control a.next").click();
        }
        if ($("body").hasClass("modal-open")) {
          //animSlide("next");
          $("#modalWrapper a.next").click();
        }
        return false;
      case 37: //prev
        if ($(".page-current .has-arrows").length) {
          $("#nav-main-control a.prev").click();
        }
        if ($("body").hasClass("modal-open")) {
          //animSlide("prev");
          $("#modalWrapper a.prev").click();
        }
        return false;
      case 27: //close (esc)
        if ($("body").hasClass("modal-open")) {
          //modalClose(); //modal
          $("#lightbox .btn-close-modal").click();
          return false;
        }
        if ($(".content-horarios").hasClass("open")) {
          $(".page-current .js-btn-calendar-close .btn").click();
        } else {
          $_btn_mainNav.click();
        }
        //if ( $('body').hasClass('openNav') ) $_btn_mainNav.click();

        return false;
      default:
        break;
    } //end switch
  }); //end keypress

  // $(document).on("swiperight", function(event) {
  //   if ( ($('.page-current .has-arrows').length) ) {
  //     $("#nav-main-control a.prev").click();
  //   }
  // });
  //  $(document).on("swipeleft", function(event) {
  //   if ( ($('.page-current .has-arrows').length) ) {
  //     $("#nav-main-control a.next").click();
  //   }
  // });

  /*-------------------------------------------------------------------------------------------
    =RESIZE on
    --------------------------------------------------------------------------------------------*/
  $_window.on(
    "resize",
    $.debounce(100, function () {
      //** =recalculate global variables
      calculateGlobalValues();
      //** =MAIN NAVIGATION
      if (Modernizr.mq("(max-width: 699px)")) {
        $("html,body").removeClass("open-nav");
        $_mainHeaderSlide.attr("style", "");
        $("#header-bg").attr("style", "");
      }

      //** =MODALIDADES ALL (need to recalculate functions)
      if ($_body.hasClass("modalidades-all") && !$_body.hasClass("mobile"))
        modalidadesPage();

      if ($_body.hasClass("modal-open")) {
        modalResize();
      }
    })
  ); //end resize window

  //RESIZE INPUT
  function resizeInput() {
    if ($("body").hasClass("mobile")) return;
    var $this = $(this);
    $this.attr("size", $this.val().length);
  }
  $("input.resizeIt")
    // event handler
    .keyup(resizeInput)
    // resize on page load
    .each(resizeInput);

  /*-------------------------------------------------------------------------------------------
    =onSCROLL
    --------------------------------------------------------------------------------------------*/
  $_window.on("scroll.nav", rAF_scroll_nav);

  function rAF_scroll_nav() {
    if (
      $_body.hasClass("mobile") ||
      $_html.hasClass("safari") ||
      $_html.hasClass("ios")
    )
      return;
    window.requestAnimationFrame(f_rAF_scroll_nav);
  }

  function f_rAF_scroll_nav() {
    var yScroll = verge.scrollY();
    if ($_body.hasClass("home")) {
      yScroll > 2 * _globalViewportH
        ? $_mainNavWrap.addClass("goBg")
        : $_mainNavWrap.removeClass("goBg");
      return;
    }
    yScroll > 100
      ? $_mainNavWrap.addClass("goBg")
      : $_mainNavWrap.removeClass("goBg");
  }

  /////////////////////////
}); //END LOAD DOCUMENT

/*******************************************************************************************
 **                                                                                       **
    =SLIDESHOWS PLUGIN SLICK
    only if i have more then 4 elements
 **                                                                                       **
*********************************************************************************************/

function slideshow_slick() {
  var $slideObg = $(".slideshow-slick");

  $slideObg.each(function () {
    var $this = $(this);

    $this.slick({
      infinite: false,
      cssEase: "cubic-bezier(.56,.13,.06,.97)",
      dots: true,
      arrows: false,
      slidesToShow: 3,
      responsive: [
        {
          breakpoint: 1271,
          settings: {
            slidesToShow: 1,
          },
        },
      ],
    }); //end plugin
  }); //end each

  $slideObg.on("init afterChange", function (event, slick, direction) {
    var $removeOver = $(".slick-slide"),
      $addOver = $(".slick-active");

    if (Modernizr.mq("(max-width: 414px)")) {
      $removeOver.removeClass("over");
    } else {
      $removeOver.removeClass("over");
      $addOver.addClass("over");
    }
  });
} //end function

/** *******************************************************************************************
     =AUDIO
*********************************************************************************************/
// ** BTN AUDIO **/
function audioBtnShow(logic) {
  logic ? $_audioBtn.removeClass("hide") : $_audioBtn.addClass("hide");
}

// ** CROSSFADE **/
function audioCrossFade($el1, $el2, time) {
  var i = 0,
    x = 0;
  $el2.volume = 0;
  $el2.play();
  $.doTimeout(time, function () {
    i = i + 1;
    x = i / 100;
    var loss = Math.cos(x * 0.5 * Math.PI); // volume down
    var gain = Math.cos((1.0 - x) * 0.5 * Math.PI); // volume up
    $el1.volume = loss;
    $el2.volume = gain;
    if (i < 100) {
      return true;
    } else {
      $el1.pause();
      $el1.currentTime = 0;
      return false;
    }
  });
} //end function audioCrassfade

// ** FADE IN OUT **/
function audioFade($el1, inout, time) {
  var i = 0,
    x = 0,
    vl = null;
  if (inout !== "out") {
    $el1.volume = 0;
    if (!$(".page-current .home").length) $el1.play();
  }

  $.doTimeout(time, function () {
    // console.log(i)
    i = i + 1;
    x = i / 100;
    if (inout === "out") {
      //var vl = Math.cos(x * 0.5*Math.PI);
      vl = 1.0 - x;
    } else {
      vl = x;
      //var vl = Math.cos((1.0 - x) * 0.5*Math.PI);
    }
    $el1.volume = vl;
    if (i < 100) {
      return true;
    } else {
      if (inout === "out") {
        if (!$(".page-current .home").length) {
          $el1.pause();
          $el1.currentTime = 0;
        }
      }
      return false;
    }
  });
} //end function audioCrassfade

// ** MUTE ALL AUDIO  **/
function audioMuteAll() {
  if (!_audioSingleM.paused) {
    audioFade(_audioSingleM, "out", 16);
  }
  if (!_audiAllM.paused) {
    audioFade(_audiAllM, "out", 16);
  }
  if ($(".page-current .home").length) {
    audioFade($homeAudio_, "out", 16);
    if (!$homeVideo_.pause()) {
      audioFade($homeVideo_, "out", 16);
    }
  }
} //end function audioCrassfade

// ** PLAY IF GLOBAL is MUTED **/
function audioUnMute() {
  if ($(".page-current .modalidades.has-arrows").length) {
    audioFade(_audioSingleM, "in", 16);
  }
  if ($(".page-current .modalidades-all").length) {
    audioFade(_audiAllM, "in", 16);
  }

  if ($(".page-current .home").length) {
    if (!$homeVideo_.pause()) {
      audioFade($homeVideo_, "in", 16);
    }
  }
} //end function audioCrassfade

/*******************************************************************************************
 **                                                                                       **
    =MODAL =LIGHTBOX
 **                                                                                       **
*********************************************************************************************/
function startModal() {
  var $modalBg = $("#modal-bg"),
    $modalWrapper = $("#modal-wrapper"),
    $modalContent = $("#modal-content"),
    $btnModal = $(".modal"),
    originalURL = "",
    originalTitle = "";

  //CLICK -event open modal (todas as modais tem de ter esta classe para abrir)
  $(document).on("click", ".modal", function (event) {
    event.preventDefault();
    event.stopPropagation();

    var $this = $(this);
    originalURL = window.location.pathname;
    originalTitle = $("head title").text();

    var forContent = $this.attr("data-ajaxUrl"),
      forUrl = $this.attr("href"),
      forTitle = $this.attr("data-title");
    //for history
    // history.replaceState({}, forTitle, forUrl);
    $("head title").html(forTitle);

    if (_global_allowNavigate) {
      openModal(forContent, originalURL, originalTitle);
    }
    return false;
  }); //end click

  //CLICK -event close modal
  $(document).on("click", "#lightbox .btn-close-modal", function (event) {
    if ($_body.hasClass("js-no-ajax")) return;

    event.stopImmediatePropagation();
    event.preventDefault();
    event.stopPropagation();

    if ($(this).parent().hasClass("promo-lightbox"))
      Cookies.set("promo-modal-closed", true);

    closeModal(originalURL, originalTitle);
  });

  //RIGHTCLICK Open brand guides
  $(document).on("contextmenu", ".brand-guides", function (event) {
    event.preventDefault();
    event.stopPropagation();

    var $this = $(this);
    originalURL = window.location.pathname;
    originalTitle = $("head title").text();

    var forContent = $this.attr("data-url-brandAjax"),
      forUrl = $this.attr("data-url-brand"),
      forTitle = $this.attr("data-title-brand");
    //for history
    history.replaceState({}, forTitle, forUrl);
    $("head title").html(forTitle);

    if (_global_allowNavigate) {
      openModal(forContent, originalURL, originalTitle);
    }
    return false;
  });

  //FUNCTION OPEN MODAL
  function openModal(forContent, originalURL, originalTitle) {
    //Evitar que sejam feitas mais chamadas ajax enquanto esta se está a processar
    _global_allowNavigate = false;
    $_body.addClass("js-loading-page");

    $modalContent.velocity(
      {
        scale: 0.7,
        opacity: 0,
      },
      0
    );
    $modalBg.velocity(
      {
        opacity: 0,
      },
      0
    );

    $modalContent.load(forContent + " .toload", function () {
      var $this = $(this);
      //Nao esquecer _global_allowNavigate = true depois de carregar e tirar a classe no ajax se abre via ajax
      $_body.removeClass("js-no-ajax "); //I am using ajax

      if (!$this.html()) {
        //go to 404
        window.location = forContent;
        return false;
      }
      $("html,body").addClass("modal-open");
      $_body.removeClass("js-loading-page");

      //ga('send', 'pageview', window.location.pathname); //analytics

      var fPreload = $("#modal-content .toload img:eq(0)").imagesLoaded();

      fPreload.always(function (instance) {
        //animation contents
        $modalContent
          .velocity(
            {
              scale: [1, 0.7],
            },
            {
              duration: 700,
              easing: [0.02, 0.78, 0, 0.95],
              queue: false,
              delay: 50,
            }
          )
          .velocity(
            {
              opacity: [1, 0],
            },
            {
              duration: 200,
              easing: "ease",
            }
          );

        $modalBg.velocity(
          {
            opacity: [0.8, 0],
          },
          {
            duration: 1000,
            easing: "ease",
            complete: endForBtnClose,
          }
        );
      });

      //Close Outside Lightbox can't delegate event to document
      $("#lightbox").on("clickoutside", function (event) {
        event.preventDefault();
        event.stopPropagation();
        event.stopImmediatePropagation();
        $("#lightbox .btn-close-modal").click();
      }); //end click

      if ($(".toload").hasClass("promo-lightbox")) {
        formPromocaoEvents();
      }

      if ($(".toload").hasClass("marcar-visita-lightbox")) {
        formMarcarVisitaEvents();
      }
      //Podemos continuar a navegar
      _global_allowNavigate = true;
    });
  } //end Open Modal

  //FUNCTION CLOSE MODAL
  function closeModal(originalURL, originalTitle) {
    $modalContent
      .velocity(
        {
          scale: [0.9, 1],
        },
        {
          duration: 700,
          easing: "ease",
          queue: false,
          delay: 50,
        }
      )
      .velocity(
        {
          opacity: [0, 1],
        },
        {
          duration: 500,
          easing: "ease",
        }
      );

    $modalBg.velocity(
      {
        opacity: [0, 0.8],
      },
      {
        duration: 1000,
        easing: "ease",
        complete: function () {
          history.replaceState({}, originalTitle, originalURL);
          $modalWrapper.removeClass("js-on");
          $("#modal-wrapper").scrollTop(0);
          $("head title").html(originalTitle);
          $("html,body").removeClass("modal-open");
          $("#lightbox").remove();
          $(".modal-open #modal-wrapper").off("scroll", scroll_fixBtnClose);
        },
      }
    );
    return false;
  } //end Close Modal

  // fixe btnClose
  function endForBtnClose() {
    $modalWrapper.addClass("js-on");
    var $socialReference = $(".modal-open #modal-wrapper .btn-close-modal");
    var offset_ = $socialReference.position();
    _globalYiniForBtnClose = offset_.top;
    $(".modal-open #modal-wrapper").on("scroll", scroll_fixBtnClose);
  } //end function

  //** =FIX Close Modal on scroll
  function scroll_fixBtnClose() {
    var YpageScrollForClose = $(".modal-open #modal-wrapper").scrollTop();

    if (YpageScrollForClose >= _globalYiniForBtnClose) {
      var aux = YpageScrollForClose - _globalYiniForBtnClose - 1;
      $(".btn-close-modal").css({ transform: "translateY(" + aux + "px)" });
    } else {
      $(".btn-close-modal").css({ transform: "translateY(0)" });
    }
  }
} //////end function startModal

/** *******************************************************************************************
    =DEFAULT BURO EVENTS
*********************************************************************************************/
function defaultBuroEvents() {
  //OPEN NEW WINDOW
  $("a[rel=external]").click(function (event) {
    event.stopImmediatePropagation();
    event.preventDefault();
    event.stopPropagation();
    var linkExterno = window.open($(this).attr("href"));
    return linkExterno.closed;
  });
  //PRINT
  $("a[rel=print]").click(function () {
    var imprimir = window.print();
    return false;
  });
  //E-MAIL: has classclass="email", e [-at-]
  $("a.email").each(function () {
    var mailReal = $(this).text().replace("[-at-]", "@");
    $(this).text(mailReal);
    $(this).attr("href", "mailto:" + mailReal);
  });
  //CLEAR FORMS
  $(
    "input[type=text], input[type=email], input.text, input.email, textarea"
  ).each(function () {
    var $this = $(this);
    if (!$this.hasClass("txtClear")) return;

    var defeito = this.defaultValue;
    $this.focus(function () {
      $this.parents("form").addClass("focus-on");
      if ($this.val() == defeito) {
        $this.val("");
      }
    });
    $this.blur(function () {
      $this.parents("form").removeClass("focus-on");
      if ($this.val() == "") {
        $this.val(defeito);
      }
      $("input.resizeIt").each(resizeInput); //exception if i have resize it
    });
  });
  //OPEN POPUP
  $(".newWindow").click(function () {
    event.stopImmediatePropagation();
    event.preventDefault();
    event.stopPropagation();
    var newwindow = window.open(
      $(this).attr("href"),
      "",
      "height=480,width=560"
    );
    if (window.focus) {
      newwindow.focus();
    }
    return false;
  });
  //MUTE SOUND ON TAB CHANGED
  Visibility.change(function (e, state) {
    if ("hidden" == state) {
      _audiAllM.volume = 0;
      _audioSingleM.volume = 0;
      if ($(".page-current .home").length) {
        if (
          !$homeVideo_.pause() &&
          !$("#home-start .bg-p").hasClass("go-video-sound")
        ) {
          $homeVideo_.pause();
        }
      }
    }
    if ("visible" == state) {
      audioUnMute();
      if ($(".page-current .home").length) {
        if (
          !$homeVideo_.pause() &&
          $("#home-start .bg-p").hasClass("go-video-sound")
        ) {
          $homeVideo_.play();
        }
      }
    }
  });

  // function fadeOutLeaveBrowser(time) {
  //   var i=0,
  //       x=0,
  //       vl = null;

  //   var audioFadeInterval = setInterval(function(){
  //      i = (i+1);
  //      x = i/10;
  //      vl = (1.0 - x);
  //      console.log("fds");
  //     if( vl <= 0) {
  //       clearInterval(audioFadeInterval);
  //     }
  //     //Variables for each page
  //     _audiAllM.volume = vl;
  //     _audioSingleM.volume = vl;
  //     $homeAudio_.volume = vl;
  //   }, time);
  // }
  //end defaultBuroEvents
}

function manageBodyClasses() {
  if ($_body.hasClass("js-no-ajax")) {
    $_body.addClass(
      $(".page-main.page-current .page-toload").attr("data-bodyClass")
    );
  } else {
    $_body.removeClass(
      $(".page-main.page-current .page-toload").attr("data-bodyClass")
    );
    $_body.addClass(
      $(".page-main.page-next .page-toload").attr("data-bodyClass")
    );
  }
}
