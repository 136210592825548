function modalidadesPage() {
  /*Variables*/
  // **  y=mx+b
  _$group_AllModal = $(".section-group-all"),
  _$videoContainer_AllModal = $(".bg-video-container");
  $power = $("#power"),
  vPower = {
      iNav: 15,
      fNav: 85,
      iScroll: 0,
      fScroll: verge.rectangle($power).height / 1.5,
      yIniPower: 0,
      $bgPower: $("#power .bg")
  };
  var $flow = $("#flow"),
      yIniFlow = $flow.offset().top;
  vFlow = {
      iNav: 319,
      fNav: 387,
      iScroll: yIniFlow - _globalViewportH / 2,
      fScroll: yIniFlow + (verge.rectangle($flow).height / 2),
      yIniFlow: yIniFlow,
      $bgFlow: $("#flow .bg")
  };
  var $moves = $("#moves"),
      yIniMoves = $moves.offset().top;
  vMoves = {
      iNav: 113,
      fNav: 197,
      iScroll: yIniMoves - _globalViewportH / 2,
      fScroll: yIniMoves + (verge.rectangle($moves).height / 2),
      yIniMoves: yIniMoves,
      $bgMoves: $("#moves .bg")
  };
  var $aqua = $("#aqua"),
      yIniAqua = $aqua.offset().top;
  vAqua = {
      iNav: 415,
      fNav: 482,
      iScroll: yIniAqua - _globalViewportH / 2,
      fScroll: yIniAqua + (verge.rectangle($aqua).height / 2),
      yIniAqua: yIniAqua,
      $bgAqua: $("#aqua .bg")
  };
  var $ride = $("#ride"),
      yIniRide = $ride.offset().top;
  vRide = {
      iNav: 225,
      fNav: 290,
      iScroll: yIniRide - _globalViewportH / 2,
      fScroll: yIniRide + (verge.rectangle($ride).height / 2),
      yIniRide: yIniRide,
      $bgRide: $("#ride .bg")
  };
  var $growup = $("#growup"),
      yIniGrowup = $growup.offset().top;
  vGrowup = {
      iNav: 510,
      fNav: 626,
      iScroll: yIniGrowup - _globalViewportH / 2,
      fScroll: yIniGrowup + (verge.rectangle($growup).height / 3),
      yIniGrowup: yIniGrowup,
      $bgGrowup: $("#growup .bg")
  };

  var $opacityNav = $(".trigger"),
      yIniOpacityNav = $opacityNav.offset().top;
  vOpacityNav = {
      iNav: 1,
      fNav: 0,
      iScroll: yIniOpacityNav - _globalViewportH,
      fScroll: $(document).height() - _globalViewportH - 300,
      yIniOpacityNav: yIniOpacityNav,
      $navModal: $(".nav-modality-all")
  };

  $.doTimeout(600, function() { /* Delay for document.height when using ajax*/
      vOpacityNav.fScroll = $(document).height() - _globalViewportH - 300;
      _mOpacityNav_AllModal = (vOpacityNav.fNav - vOpacityNav.iNav) / (vOpacityNav.fScroll - vOpacityNav.iScroll);
      _bOpacityNav_AllModal = vOpacityNav.iNav - _mOpacityNav_AllModal * vOpacityNav.iScroll;
  })


  //** =SVG TITLE
  _mTitle_AllModal = (0.95 - 0.15) / (_globalViewportH / 2 - 0);
  _bFlowTitle_AllModal = 0.15 - _mTitle_AllModal * yIniFlow;
  _bMovesTitle_AllModal = 0.15 - _mTitle_AllModal * yIniMoves;
  _bAquaTitle_AllModal = 0.15 - _mTitle_AllModal * yIniAqua;
  _bRideTitle_AllModal = 0.15 - _mTitle_AllModal * yIniRide;
  _bGrowupTitle_AllModal = 0.15 - _mTitle_AllModal * yIniGrowup;

  //** =CLICK NAV
  var $navSec = $(".nav-modality-all a"),
      $NavSecLi = $(".nav-modality-all li"),
      $NavSecCrl = $(".nav-modality-all .crl"),
      $navCities = $(".cities-picker");

  $firstVideo = $(".section-group-all").eq(0).find("video")[0];

  if (!$_body.hasClass('mobile'))
    $firstVideoPromise_ = $firstVideo.play();

  /*DOM variables*/

  var init = function() {

    if(_customScroll == null) {
      /*Attach the event with a reference to kill after exit */
      $_window.on("scroll.modalidades", rAF_scroll_ModalidadesP);
    }
    else {
      _page_scroll_listener = function(status) {
        Modalidades_scroll_rAF(status);
      };

      _customScroll.addListener(_page_scroll_listener);
    }
    console.log("modalidades page");

    /*inits*/
    if (!$_body.hasClass('mobile')) {
      if ($firstVideoPromise_ !== undefined) {
        $firstVideoPromise_.then(function() {
          // =audio start
          if( !$_body.hasClass("mobile") ) {
            if ( !_audioSingleM.paused  ) {
                _audioSingleM.pause();
                _audioSingleM.currentTime = 0;
            }
            _audiAllM.pause();
            _audiAllM.currentTime = 0;
            if ( _audioGlobal ) {
                _audiAllM.play();
                _audiAllM.volume=1;
            }
          }
        }).catch(function(error) {
          //error
        });
      }
    }

    //Events
    $(".cities-picker ul li").on("click", function() {
      var $this = $(this),
          target = $this.attr("data-target");

      TweenMax.to(".cities-picker ul li", .5, {
        className:"-=active"
      });

      TweenMax.to($this, .5, {
        className:"+=active"
      });

      if(target == 'all') {
        TweenMax.to(".nav-modality-list li", .5, {
          opacity: 1,
          pointerEvents: "initial"
        });
      }
      else {
        TweenMax.to(".nav-modality-list li", .5, {
          opacity: .2,
          pointerEvents: "none"
        });

        TweenMax.to("."+target, .5, {
          opacity: 1,
          pointerEvents: "initial"
        });
      }
    })

    audioBtnShow(true);

    $navSec.click(function(event) {
        var $this = $(this),
            vHash = $this.attr("href");
        _global_useViewPort = false;
        allModalNavSec($this);
        event.preventDefault();
    })

    //** =ONSTART PAGE
    allModalNavSec($(".nav-modality-all a[href=#power]")); //power has class on
    $("#power .bg-video-container").addClass("fixed-always"); //first video this class only after transition animation

    //DEFAULT EVENTS
    initEvents();
    if(!$_body.hasClass("mobile"))
      $_window.on("resize.resizeEventModalidades", $.debounce(500, resizeModalidades));
  }

  var kill = function() {
    //Kill Events
    _$group_AllModal = null,
    _$videoContainer_AllModal = null;
    vPower = null,
    vFlow = null,
    vMoves = null,
    vAqua = null,
    vRide = null,
    vGrowup = null;
    vOpacityNav = null,
    _mOpacityNav_AllModal = null,
    _bOpacityNav_AllModal = null;
    _mTitle_AllModal = null;
    _bFlowTitle_AllModal = null;
    _bMovesTitle_AllModal = null;
    _bAquaTitle_AllModal = null;
    _bRideTitle_AllModal = null;
    _bGrowupTitle_AllModal = null;

    //DEFAULT KILL EVENTS
    $_window.off("scroll.modalidades");
    $_window.off("resize.resizeEventModalidades");

    _scrollRef = null;
    cancelAnimationFrame(_raf_main_id);

    if(_customScroll)
      _customScroll.removeListener(_page_scroll_listener);
  }

  /*page functions*/
  function initEvents() {

  }

  function allModalNavSec($this) {
    var $navSec = $(".nav-modality-all a"),
        $NavSecLi = $(".nav-modality-all li"),
        $NavSecCrl = $(".nav-modality-all .crl");

    var resize = $this.find(".txt").height();

    $navSec.removeClass('on');
    $this.addClass('on');

    $NavSecLi.css("height", "18px");
    $this.parent("li").css("height", resize);

    $NavSecCrl.css("height", "18px");
    $this.find(".crl").css("height", resize);
  }

  function resizeModalidades() {
    if (!$_body.hasClass('mobile')) {

    }
  }

  function rAF_scroll_ModalidadesP() {
    if (!$_body.hasClass('mobile')) {
      window.requestAnimationFrame(f_rAF_scroll_ModalidadesP);
    }
  }

  function f_rAF_scroll_ModalidadesP(status) {
    var scrollVal = verge.scrollY();

    _$group_AllModal.each(function(i) {
        var $this = $(this);
        var $thisVideoContainer = $this.find(".bg-video-container");
        var $thisTitleSvg = $this.find(".header-group");
        var $thisTitleSvgBg = $this.find(".header-group .bg");

        /// =Video show/hide
        if (!verge.inY($this, 0)) {
            $thisVideoContainer.addClass("off");
            if (!$_body.hasClass('mobile')) {
                $thisVideoContainer.find("video").get(0).pause();
            }
        }
        ; //end if

        if (verge.inY($this, 0)) {
            $thisVideoContainer.removeClass("off");
            if (!$_body.hasClass('mobile')) {
                $thisVideoContainer.find("video").get(0).play();
            }
            if (verge.rectangle($thisVideoContainer).top <= 0) {
                //do not fixe last video, to have acces to the footer
                _$videoContainer_AllModal.removeClass('fixed');
                if (!$thisVideoContainer.hasClass("last")) {
                    $thisVideoContainer.addClass('fixed');
                }

                //** SVG TITLE (need start function before normal yScrollIni for not having fliker)
                $(".header-group").removeClass('header-fixed');
                if (!$thisVideoContainer.hasClass("last")) {
                    $thisTitleSvg.addClass('header-fixed');
                }

                var opac = _mTitle_AllModal * scrollVal + 0.15;
                if (scrollVal > vFlow.iScroll) {
                    opac = _mTitle_AllModal * scrollVal + _bFlowTitle_AllModal;
                }
                if (scrollVal > vMoves.iScroll) {
                    opac = _mTitle_AllModal * scrollVal + _bMovesTitle_AllModal;
                }
                if (scrollVal > vAqua.iScroll) {
                    opac = _mTitle_AllModal * scrollVal + _bAquaTitle_AllModal;
                }
                if (scrollVal > vRide.iScroll) {
                    opac = _mTitle_AllModal * scrollVal + _bRideTitle_AllModal;
                }
                if (scrollVal > vGrowup.iScroll) {
                    opac = _mTitle_AllModal * scrollVal + _bGrowupTitle_AllModal;
                }

                if( opac >= .95) opac = .95;
                $thisTitleSvgBg.css("opacity", opac);
            }

        } //end if on viewport

        //** FOR SEC NAVIGATION
        if (verge.inY($this, -_globalHalfViewportH)) {
            if (!_global_useViewPort) return;
            var aux = $this.attr("id"),
                auxHash = "#" + aux;
            var $elemento = $(".nav-modality-all a[href='" + auxHash + "']");
            allModalNavSec($elemento);
        }
    }) //end each group

    //** SVG TITLE default values
    var auxH = _globalViewportH / 2;
    if (scrollVal <= vPower.yIniPower) {
        vPower.$bgPower.css("opacity", .15);
    }
    if (scrollVal > vPower.yIniPower + auxH) {
        vPower.$bgPower.css("opacity", .95);
    }

    if (scrollVal < vFlow.yIniFlow) {
        vFlow.$bgFlow.css("opacity", .15);
    }
    if (scrollVal > vFlow.yIniFlow + auxH) {
        vFlow.$bgFlow.css("opacity", .95);
    }

    if (scrollVal < vMoves.yIniMoves) {
        vMoves.$bgMoves.css("opacity", .15);
    }
    if (scrollVal > vMoves.yIniMoves + auxH) {
        vMoves.$bgMoves.css("opacity", .95);
    }

    if (scrollVal < vAqua.yIniAqua) {
        vAqua.$bgAqua.css("opacity", .15);
    }
    if (scrollVal > vAqua.yIniAqua + auxH) {
        vAqua.$bgAqua.css("opacity", .95);
    }

    if (scrollVal < vRide.yIniRide) {
        vRide.$bgRide.css("opacity", .15);
    }
    if (scrollVal > vRide.yIniRide + auxH) {
        vRide.$bgRide.css("opacity", .95);
    }

    if (scrollVal < vGrowup.yIniGrowup) {
        vGrowup.$bgGrowup.css("opacity", .15);
    }
    if (scrollVal > vGrowup.yIniGrowup + auxH) {
        vGrowup.$bgGrowup.css("opacity", .95);
    }

    //** SEC NAV Opacity
    if (scrollVal < vOpacityNav.iScroll) {
        vOpacityNav.$navModal.css({
          "opacity": "1",
          "visibility": "visible"
        });
    }
    if (verge.inY($(".trigger"), 0)) {
      var opac = _mOpacityNav_AllModal * scrollVal + _bOpacityNav_AllModal;
      vOpacityNav.$navModal.css({
        "opacity": opac,
        "visibility": "hidden"
      });

      TweenMax.to($navCities, .5, {
        autoAlpha: 0
      });
    } else {
      TweenMax.to($navCities, .5, {
        autoAlpha: 1
      });
    }
  }

  return {
    init: init,
    kill: kill
  }
}