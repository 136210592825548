function teamSinglePage() {
  /*Variables*/

  /*DOM variables*/

  var init = function() {

    if(_customScroll == null) {
      /*Attach the event with a reference to kill after exit */
      $_window.on("scroll.teamSingle", rAF_scroll_TeamSingleP);
    }
    else {
      _page_scroll_listener = function(status) {
        TeamSingle_scroll_rAF(status);
      };

      _customScroll.addListener(_page_scroll_listener);
    }
    console.log("team-single page");

    /*inits*/
    initiateInstrutorForm();

    //Events
    audioBtnShow(false);

    //DEFAULT EVENTS
    initEvents();
    if(!$_body.hasClass("mobile"))
      $_window.on("resize.resizeEventTeamSingle", $.debounce(500, resizeTeamSingle));
  }

  var kill = function() {
    //Kill Events

    //DEFAULT KILL EVENTS
    $_window.off("scroll.teamSingle");
    $_window.off("resize.resizeEventTeamSingle");
    
    _scrollRef = null;
    cancelAnimationFrame(_raf_main_id);

    if(_customScroll)
      _customScroll.removeListener(_page_scroll_listener);
  }

  /*page functions*/
  function initEvents() {
    //Bloquear o evento do <a> do horario para não saltar para o topo da pagina
    var $noClick = $("a.mod-no-click");
    $noClick.on("click", function(event){
      event.preventDefault();
    });

    // SWIPE
    var $toswipe = $(".modality-pic, .instruct-info-lft h2, .instruct-info-rgt"),
        $nextBtn = $("#nav-main-control a.next"),
        $prevBtn = $("#nav-main-control a.prev");
    $toswipe.on("swiperight",function (e,data){
        $prevBtn.click();
        e.preventDefault();
    })
    $toswipe.on("swipeleft",function (e,data){
        $nextBtn.click();
        e.preventDefault();
    })

    //SELECT form
    var $select1 = $("#team-modality"),
        $selectedTxt1 = $("#team-modality-txt"),
        $select2 = $("#team-gym"),
        $selectedTxt2 = $("#team-gym-txt");

    $select1.change(function() {
        var option = $("#team-modality option:selected").text();
        var $select = $(this);
        $selectedTxt1.text(option);
        resizeSelect($select, $selectedTxt1);
    })
    $select2.change(function() {
        var option = $("#team-gym option:selected").text();
        var $select = $(this);
        $selectedTxt2.text(option);
        resizeSelect($select, $selectedTxt2);
    })
    resizeSelect($select1, $selectedTxt1);
    resizeSelect($select2, $selectedTxt2);
    //Resize Input
    $("input.resizeIt").keyup(resizeInput).each(resizeInput);
    $("input.email").attr('size', '3'); // bug for txt email with this type

    //OVER MODALIDADES - title
    var $linkModality = $(".list-modality a"),
        $nameModality = $(".name-modality .anim-txt-ud");

    $linkModality.mouseenter(function(e){
        var $this = $(this),
            txtModality = $this.find(".txt").text();
        var direction = null,
            stopThis = null,
            x_old = 0;

        $this.mousemove(function(e){
          if ( stopThis ) return;
          if ( e.pageX < x_old ) {
            direction = "lft";
          } else if ( e.pageX > x_old) {
            direction = "rgt";
          }
            x_old = e.pageX;
        })
        $.doTimeout(50, function() {
            stopThis = true;
            $nameModality.text(txtModality);
            if (direction == "lft") {
              $nameModality.addClass("down");
            } else {
              $nameModality.addClass("up");
            }
        })
    })//end mouseenter

    $linkModality.mouseleave(function(e){
      $nameModality.removeClass("up down");
      $.doTimeout(50, function() { $nameModality.removeClass("up down"); }) //make sure doesnt stay on
    })//end mouseleave
  }

  //=FORM SELECTED FIELDS =team =intrutores
  function resizeSelect($selected, $txtElement) {
      var forpadding = 45;
      if ($_html.hasClass('mobile')) {
          forpadding = 52;
      }
      var comp = $txtElement[0].offsetWidth + forpadding;
      $selected.css("width", comp);
  }

  function resizeTeamSingle() {

  }

  function rAF_scroll_TeamSingleP() {
    if (!$_body.hasClass('mobile')) {
      window.requestAnimationFrame(f_rAF_scroll_TeamSingleP);
    }
  }

  function f_rAF_scroll_TeamSingleP(status) {
    // var $viewportControl = $(".vpControl"),
    var $picture = $(".instruct-pic"),
        $btnScrollDown = $(".team .center-stage");

    var scrollY = verge.scrollY();
    var opaI = 1,
        opaF = .45,
        yI = _globalViewportH,
        yF = 1.5 * _globalViewportH,
        m = ((opaF - opaI) / (yF - yI)),
        b = 1 - m * yI,
        yIBtn = yI + 171; /* (77 - margin bottom +94 height)*/

    //opacity image
    if (scrollY >= yI) {
        var opacF = m * scrollY + b;
        if (opacF <= opaF) {
            opacF = opaF;
        }
        $picture.css("opacity", opacF);
        if (scrollY >= yI + 77 + 97) {
            $btnScrollDown.css("bottom", "-77px");
        }
    } else {
        $picture.css("opacity", 1);
        $btnScrollDown.attr("style", "");
    }
    //btnscroll down
    if (scrollY >= yIBtn) {
        $btnScrollDown.css("bottom", "-177px");
    } else {
        $btnScrollDown.attr("style", "");
    }
  }

  return {
    init: init,
    kill: kill
  }
}