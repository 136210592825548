function inscricoesPage() {
  /*Variables*/
  var $navInsc      = $("#nav-inscricoes button"),
      $navInscWrap      = $("#nav-inscricoes-wrapper"),
      vHeader           = $("#nav-inscricoes .text");
  var $menuInsc     = $("#nav-inscricoes ul"),
  $menuInscList     = $("#nav-inscricoes li");
  var $menuInscLink = $("#nav-inscricoes a");

  //** =SVG menu
  var svgContainer = document.querySelector('div.morph-svg');
  var svgElement   = svgContainer.querySelector('svg');
  var resetShape   = $(".morph-svg path").attr("d");
  var newShapeEnd  = $(".morph-svg").attr("data-morph-end");
  var svgMenu      = Snap(svgElement);
  var pathSVG      = svgMenu.select('path');

  /*DOM variables*/

  var init = function() {

    if(_customScroll == null) {
      /*Attach the event with a reference to kill after exit */
      $_window.on("scroll.inscricoes", rAF_scroll_InscricoesP);
    }
    else {
      _page_scroll_listener = function(status) {
        inscricoes_scroll_rAF(status);
      };

      _customScroll.addListener(_page_scroll_listener);
    }
    console.log("inscricoes page");

    /*inits*/
    initiateInscricoesForms();

    //Events
    audioBtnShow(false);

    //DEFAULT EVENTS
    initEvents();
    if(!$_body.hasClass("mobile"))
      $_window.on("resize.resizeEventInscricoes", $.debounce(500, resizeInscricoes));
  }

  var kill = function() {
    //Kill Events

    //DEFAULT KILL EVENTS
    $_window.off("scroll.inscricoes");
    $_window.off("resize.resizeEventInscricoes");

    _scrollRef = null;
    cancelAnimationFrame(_raf_main_id);

    if(_customScroll)
      _customScroll.removeListener(_page_scroll_listener);
  }

  /*page functions*/
  function initEvents() {
    //** =MENU
    $navInsc.on("click", function() {
        var startedPage = false;
        $navInscWrap.toggleClass('open');

        if ($navInscWrap.hasClass('open')) {
            navIn();
        } else {
            navOut();
        }
        return false;
    }) //end CLICK

    $menuInscLink.on("click", function(event) {
        var $this = $(this),
            vHash = $this.attr("href"),
            vText = $this.text();

        vHeader.text(vText);
        window.location.hash = vHash;

        if (!$navInscWrap.hasClass('open')) return false;
        $navInsc.click();
        return false;
    }) //end CLICK


    function navIn() {
        $menuInscList.velocity(
            "transition.slideDownIn", {
                stagger: 50,
                duration: 200,
                delay: 50
            });

        pathSVG.stop().animate({
            "path": newShapeEnd
        }, 250, mina.easeout);
    } //end function

    function navOut() {
        $menuInscList.velocity(
            "transition.slideUpOut", {
                stagger: 0,
                duration: 200,
                delay: 0
            })
        pathSVG.stop().animate({
            "path": resetShape
        }, 250, mina.easeout);
    } //end function

    $navInsc.on("clickoutside", function(event) {
        if (!$navInscWrap.hasClass('open')) return false;
        $navInsc.click();
        event.preventDefault();
    })

    $menuInscLink.click(function(e) {
        var $this = $(this);
        var thisHref = $this.attr('href');
        var aux = thisHref.replace("#", ".");
        var $whereTo = $("" + aux + "");

        $menuInscLink.removeClass('on');
        $this.addClass('on');

        $(".inscricoes-form").hide();
        $whereTo.show();
        //reset totals
        totalToPay(true, $this);

        e.preventDefault;
        //end click
    })

    //** =TOOLTIP
    $(".icon-svg-auto.info")
        .mouseover(function(e) {
            $(this).doTimeout('tooltip', 200, tooltipHover, this);
        }).mouseout(function() {
        $(this).doTimeout('tooltip', 0, tooltipOut, this);
    })

    function tooltipHover(elem) {
        $(elem).next().velocity("transition.flipXIn", 170);
    }
    function tooltipOut(elem) {
        $(elem).next().velocity("transition.expandOut", 120);
    }


    //** START
    var hasHash = window.location.hash;
    if (!hasHash) {
        hasHash = "#lagrimas";
    }
    $("#nav-inscricoes a[href=" + hasHash + "]").click();

    //** =FORMS **
    var valorInscricao = $(".add-inscricao").text();


    $(".planos .main-selection").click(function(event) {
        var $this = $(this);

        $(".planos .main-selection").removeClass('selected');
        $this.addClass('selected');

        $this.parent().find(".radio-planos").prop('checked', false);
        $this.find(".radio-planos").prop('checked', true);

        //reset the other one
        $(".periocidade .inputType-radio").removeClass('selected');
        $(".periocidade .inputType-radio").find("input").prop('checked', false);
        $(".planos .main-selection").attr("data-option", "");

        //dropdown
        $(".periocidade").velocity(
            "slideUp", {
                duration: 200
            });
        $this.parent().find(".periocidade").velocity(
            "slideDown", {
                delay: 10,
                duration: 400,
                easing: [150, 18]
            });

        event.preventDefault();
        event.stopPropagation();
    }) //end click


    $(".periocidade .inputType-radio").click(function(event) {
        var $this = $(this),
            forPlanos = $this.attr("data-option"),
            forInscricao = $this.attr("data-inscricao");

        $this.parents(".column").find(".main-selection").attr("data-option", forPlanos);

        $this.parent().find(".inputType-radio").removeClass('selected');
        $this.addClass('selected');

        $this.parent().find("input").prop('checked', false);
        $this.find("input").prop('checked', true);

        $(".table-inscricoes .add-inscricao").text(forInscricao);
        var tipoDePagamento = $this.find("label").attr("data-tipo");
        var valorDePagamento = $this.find("input").val();
        var valorDeSeguro = $this.parents('form').find('.add-seguro').text();

        $(".table-inscricoes .valor-periocidade .add-tipo").text(tipoDePagamento);
        $(".table-inscricoes .valor-periocidade .add").text(valorDePagamento);

        //alert( $(this).find("input:checked").val() );
        var $table = $this.parents("form").find(".table-inscricoes");
        totalToPay(false, $table);

        if (forPlanos == 'anual-selected') {
            $this.parents('form').find('.end').css('display', 'block');

            $this.parents('form').find('.end-tipo').text('anuidade');
            var aux = parseFloat(valorDePagamento) + parseFloat(valorDeSeguro);
            $this.parents('form').find('.end strong').text("€" + aux.toFixed(2));

        } else if (forPlanos == 'mensal-selected') {
            $this.parents('form').find('.end').css('display', 'block');

            $this.parents('form').find('.end-tipo').text('mensalidade');
            $this.parents('form').find('.end strong').text("€" + valorDePagamento);
        } else {
            $this.parents('form').find('.end').css('display', 'block');

            if ($this.parents('form').hasClass('celas')) {
                $this.parents('form').find('.end p').html('Valor de iniciação, o próximo <span class="end-tipo">pagamento</span> será de <strong>€' + valorDePagamento + '.</strong>');
            } else {
                $this.parents('form').find('.end-tipo').text('anuidade');
                var aux = parseFloat(valorDePagamento) + parseFloat(valorDeSeguro);
                $this.parents('form').find('.end strong').text("€" + aux.toFixed(2));
            }
        }

        event.preventDefault();
        event.stopPropagation();
    }) //end click

    $(".pagamento .inputType-radio").click(function(event) {
        var $this = $(this);

        $this.parent().find(".inputType-radio").removeClass('selected');
        $this.addClass('selected');

        $this.parent().find("input").prop('checked', false);
        $this.find("input").prop('checked', true);

        event.preventDefault();
        event.stopPropagation();
    }) //end click


    function totalToPay(reset, $element) {
        var $total = $element.find(".valor-total"),
            $error = $element.find(".total"),
            soma = 0 ,
            somaTotal = 0;

        if (reset) {
            $(".add-inscricao, .add-tipo, .valor-total, .valor-periocidade .add").text("—");
            $error.removeClass('error');
            return;
        }

        $element.find(".add").each(function(i) {
            // $(".table-inscricoes .add").each(function(i){
            var $this = $(this),
                value = parseFloat($this.text());
            soma = soma + value;
        }) //end each
        if ((typeof soma) !== "number" || isNaN(soma)) {
            $error.addClass('error');
            return;
        } else {
            $error.removeClass('error');
            somaTotal = soma.toFixed(2);
        }

        $total.text(somaTotal);
    } //end function
  }

  function resizeInscricoes() {

  }

  function rAF_scroll_InscricoesP() {
    if (!$_body.hasClass('mobile')) {
      window.requestAnimationFrame(f_rAF_scroll_InscricoesP);
    }
  }

  function f_rAF_scroll_InscricoesP(status) {

  }

  return {
    init: init,
    kill: kill
  }
}