function homePage() {
  /*Variables*/
  var $campaign = $(".campaign-monitor"),
      $campaignCard = $(".campaign-monitor-wrapper"),
      title = $campaign.find("ul li a").text(),
      link = $campaign.find("ul li a").attr("href");
      $campaignCard.find("a").attr("href", link);
      $campaignCard.find(".campaign-monitor-title").text(title),
      scrollVal = 0,
      scrollLimit = 0,
      page_loop = 0,
      scrollDirection = null,
      sliderHome = null;

  var $svgP = $(".home-p"),
      $svgPcontainer = $("#home-start .bg-p"),
      $line = $("#home-start .line"),
      $homeVideo = $("#home-video"),
      videoTotalTime = $homeVideo.attr("data-time"),
      homeAudioPlayed = false,
      mouseX = 0,
      container_offset = $(".content-map-home .gym-column").width()*$(".content-map-home .gym-column").length - _globalViewportW;

  $homeVideo_ = $("#home-video")[0];
  $homeAudio_ = $("#soundfx_phive")[0];

  if (!$_body.hasClass('mobile'))
    $homeVideoPromise_ = $homeVideo_.play();

  var obgHomeHeadini = {};
      obgHomeHeadini.xi = 0;
      obgHomeHeadini.xf = _globalViewportH/2;
      obgHomeHeadini.yi = 1;
      obgHomeHeadini.yf = 3;
      obgHomeHeadini.m = (obgHomeHeadini.yf-obgHomeHeadini.yi)/(obgHomeHeadini.xf-obgHomeHeadini.xi);
      obgHomeHeadini.b = obgHomeHeadini.yi-obgHomeHeadini.m*obgHomeHeadini.xi;

  var homeHeadLine = {};
      homeHeadLine.xi = 0;
      homeHeadLine.xf = 1.5*_globalViewportH;
      homeHeadLine.yi = -200;
      homeHeadLine.yf = 0;
      homeHeadLine.m = (homeHeadLine.yf-homeHeadLine.yi)/(homeHeadLine.xf-homeHeadLine.xi);
      homeHeadLine.b = homeHeadLine.yi-homeHeadLine.m*homeHeadLine.xi;

  var $gymBlock = $(".content-gym-home"),
      $gymFixedBlock = $(".content-gym-home .visual"),
      $gymInfo = $(".content-gym-home .info"),
      $gymPics = $(".content-gym-home .toRect"),
      $gymInfoTrigger = $(".content-gym-home .triggerToRect"),
      height_gymInfoTrigger = [];

  //Prepare svg anim
  if ( !$_body.hasClass('mobile') ) {
    var svgModal36Anim = new Vivus('svg-modal36-anim', {
                            type: "async",
                            duration: 45,
                            start: "manual"
                         });
    var svgCrossfitAnim = new Vivus('svg-crossfit-anim', {
                            type: "delayed",
                            duration: 45,
                            start: "manual"
                         });
    var svgMassagAnim = new Vivus('svg-massag-anim', {
                            type: "delayed",
                            duration: 45,
                            start: "manual"
                         });
    var svgModal24Anim = new Vivus('svg-modal24-anim', {
                            type: "async",
                            duration: 45,
                            start: "manual"
                         });
    var svgPoolAnim = new Vivus('svg-pool-anim', {
                            type: "async",
                            duration: 35,
                            start: "manual"
                         });
    var svgSpaAnim = new Vivus('svg-spa-anim', {
                            type: "async",
                            duration: 90,
                            start: "manual"
                         });
  }

  /*DOM variables*/

  var init = function() {

    if(_customScroll == null) {
      /*Attach the event with a reference to kill after exit */
      $_window.on("scroll.home", rAF_scroll_HomeP);
    }
    else {
      _page_scroll_listener = function(status) {
        home_scroll_rAF(status);
      };

      _customScroll.addListener(_page_scroll_listener);
    }
    console.log("home page");

    /*inits*/
    page_loop = _rAF_loop(animationLoop);

    //Events
    audioBtnShow(true);

    if ($_body.hasClass('mobile')) {
      var sliderHome = new Swiper ('.content-map-home', {
        grabCursor: true,
        slidesPerView: 'auto',
        resistanceRatio: 0.5,
        navigation: {
          nextEl: '.next.gym-btn',
          prevEl: '.prev.gym-btn',
        },
        wrapperClass: 'scroll-wrapper-horizontal',
        slideClass: 'gym-column'
      });
    }

    if (!$_body.hasClass('mobile')) {
      if ($homeVideoPromise_ !== undefined) {
        $homeVideoPromise_.then(function() {
          $homeVideo_.pause();
        }).catch(function(error) {
          //error
        });
      }
    }

    //DEFAULT EVENTS
    initEvents();
    if(!$_body.hasClass("mobile"))
      $_window.on("resize.resizeEventHome", $.debounce(500, resizeHome));

    if($_body.hasClass("mobile"))
      $_window.on("orientationchange.orientationEventHome", $.debounce(500, orientationHome));
  }

  var kill = function() {
    //Kill Events
    videoEndedControl(false);
    $(".content-map-home").off("mousemove");
    // VirtualScroll.off();
    window.cancelAnimationFrame(page_loop);
    if(sliderHome)
      sliderHome.destroy();

    //DEFAULT KILL EVENTS
    $_window.off("scroll.home");
    $_window.off("resize.resizeEventHome");
    $_window.off("orientationchange.orientationEventHome");

    _scrollRef = null;
    cancelAnimationFrame(_raf_main_id);

    if(_customScroll)
      _customScroll.removeListener(_page_scroll_listener);
  }

  /*page functions*/
  function initEvents() {
    /*Modalidades Random por ajax para contornar a cache*/
    var server_hostname = document.location.origin;
    $.ajax({
      url: server_hostname + '/wp-admin/admin-ajax.php',
      data: {
          action: 'initRandomModalidades'
      },
      success: function(data) {
          $('.list-modal-home').append(data);
      },
      error: function(data){
      }
    });

    if($_body.hasClass("mobile"))
      $_mainHeader.addClass("show");

    $line.addClass("startScroll");

    if ( !$_body.hasClass('mobile') ) {
      $(".sentence, .break").blast({
          delimiter: "character",
          search: false,
          tag: "span",
          customClass: "" ,
          generateIndexID: false,
          generateValueClass: false,
          stripHTMLTags: false,
          returnGenerated: true,
          aria: true
      });
    }
    else {
        TweenMax.set($("#home-start .call-to-action-leiria"), {
          autoAlpha: 1
        })
    }
    $("#home-start .sentence").css("opacity","1");
    if ( !$_body.hasClass('mobile') ) {
      if ( !$_html.hasClass('firefox') ) {
          $(".parallax-home-start").buroParallax({
              scale: false,
              scaleValue: 1,
              rotate: false,
              rotateYdy: 2.03,
              rotateXdx: 2.035,
              usePerspective: false,
              shineGlow: false,
              resetPositions: false,
              destroy: false
          });
      };
    }

    //*** GINASIOS GYM *** //

    //start cliprect
    $gymInfoTrigger.each(function(i) {
        var $this = $(this),
            positionYini = $this.offset().top - $this.innerHeight(),
            positionYend = positionYini + $this.innerHeight();
        height_gymInfoTrigger[i] = {
            iniScroll: positionYini,
            endScroll: positionYend
        }
    });//end each
    $gymPics.css("clip", "rect("+_globalViewportH+"px, "+_globalViewportW+"px, "+_globalViewportH+"px, 0px)");

    //*** MODALIDADES (use of .on because comes from ajax) *** //
    if (Modernizr.mq('(min-width: 414px)')) {
        $(document).on("mouseenter", ".modality-home .circle-btn", function(){
          var $this = $(this);
          $this.parent().addClass( "over" );
        })
        $(document).on("mouseleave", ".modality-home .circle-btn", function(){
          var $this = $(this);
          $this.parent().removeClass( "over" );
        })
    }


    //*** CAMPANHAS ** //
    if ( !$_body.hasClass('mobile') ) {
      if ( $_html.hasClass('chrome') ) {
          // $(".parallax-campg").buroParallax({
          //     scale: true,
          //     scaleValue: 1.020,
          //     rotate: false,
          //     rotateYdy: 0.03,
          //     rotateXdx: 0.035,
          //     usePerspective: true,
          //     shineGlow: true,
          //     resetPositions: true,
          //     destroy: false
          // })
      }
    }


    //**** =HASHTAG ****\\
    var hasHash = window.location.hash;
    if (hasHash != "") {
        goTo($("" + hasHash + ""), 2200, "easeInOutCubic");
    }

    // $(".gym-btn").on("mouseenter", function() {
    //   var $this = $(this);
    //   $this.find(".bg-hover").doTimeout("fOver", 100, "addClass", "over");
    //   event.preventDefault();
    // }).on("mouseleave", function() {
    //   var $this = $(this);
    //   $this.find(".bg-hover").doTimeout("fOver", 0, "removeClass", "over");
    //   event.preventDefault();
    // });

    if (!$_body.hasClass('mobile')) {
      $(".content-map-home").on("mousemove", function(event) {
        mouseX = event.clientX;
      });
    }
  }

  function videoEndedControl(start) {
    if ( !start ) {
      $.doTimeout("videoisplaying",false);
      return false;
    }

    $.doTimeout("videoisplaying", 100, function(){
      if ( $homeVideo_.currentTime >= videoTotalTime  && !$homeVideo_.paused) {
        goTo( $(".content-modal-home"), 1500, [0.7,0,0.3,1], 0);
        return false;
      }
      return true;
    })//end timeout
  }//end videoEndedControl

  function resizeHome() {
    $gymInfo.removeClass("goAnim");
    svgModal36Anim.play();
    svgCrossfitAnim.play();
    svgMassagAnim.play();
    svgModal24Anim.play();
    svgPoolAnim.play();
    svgSpaAnim.play();
    container_offset = $(".content-map-home .gym-column").width()*$(".content-map-home .gym-column").length - _globalViewportW;
  }

  function orientationHome() {
    var sliderHome = document.querySelector('.content-map-home').swiper;
    sliderHome.update();
  }

  function rAF_scroll_HomeP() {
    if (!$_body.hasClass('mobile')) {
      window.requestAnimationFrame(f_rAF_scroll_HomeP);
    }
  }

  function animationLoop() {
    page_loop = _rAF_loop(animationLoop);

    if (!$_body.hasClass('mobile')) {
      var offset = scaleBetween(mouseX, 0, container_offset, 0, _globalViewportW);

      TweenMax.to($(".scroll-wrapper-horizontal"), 1, {
        x : -offset,
        ease: Power4.easeOut
      })
    }
  }

  function f_rAF_scroll_HomeP(status) {
    var yScroll = verge.scrollY(),
        scaleIni = obgHomeHeadini.m * yScroll + obgHomeHeadini.b;
        lineH = homeHeadLine.m * yScroll +homeHeadLine.b;
            // scaleEnd = obgHomeHeadEnd.m * yScroll + obgHomeHeadEnd.b;

        //*** HEADER *** //
    ( yScroll > 50 ) ? $svgPcontainer.addClass("fadeInsideP") : $svgPcontainer.removeClass("fadeInsideP");
    ( yScroll > 5 ) ? $line.removeClass("startScroll") : $line.addClass("startScroll");

    if ( yScroll > 300 ){
     // if(!$_body.hasClass("mobile")) $_mainHeader.addClass("show");

      if ( !$(".f1").hasClass("in") ) {
        TweenMax.staggerTo($(".f1 span"), .4, {
          y: "0%",
          ease: Circ.easeOut
        }, .02);
        if ( $(".f1").addClass("in") );
        $(".call-to-action-leiria").addClass("in");
      }
    } else {
      //if(!$_body.hasClass("mobile")) $_mainHeader.removeClass("show");
      $(".call-to-action-leiria").removeClass("in");

      if ( !$(".f1").hasClass("out") ) {
        if ( $(".f1").hasClass("in") ) {
            TweenMax.staggerTo($(".f1 span"), .4, {
              y: "-100%",
              ease: Circ.easeIn
            }, .02);
            if ( $(".f1").addClass("out") );
        }
      }
    }

    if ( yScroll <= 5 ){
      $(".f1").removeClass("in out")
    }

    // LINE
    $line.css("backgroundPosition", "0" +lineH+"px");
    //BG P
    if (!$_body.hasClass('mobile')) {
      if ( yScroll <= 300 ) {
        $svgPcontainer.removeClass("go-p");
        $svgPcontainer.removeClass("go-video-sound");
        $svgP.css("transform", "scale3d("+scaleIni+","+scaleIni+",1)");
        if ($homeVideoPromise_ !== undefined) {
          $homeVideoPromise_.then(function() {
            $homeVideo_.pause();
            videoEndedControl(false);
          }).catch(function(error) {
            //error
          });
        }
      } else if ( yScroll >= 1.5*_globalViewportH ) {
        $svgPcontainer.removeClass("go-video-sound");
        $line.css("backgroundPosition", "0 0");
        if ($homeVideoPromise_ !== undefined) {
          $homeVideoPromise_.then(function() {
            $homeVideo_.pause();
            videoEndedControl(false);
          }).catch(function(error) {
            //error
          });
        }
      } else {
        if ($homeVideoPromise_ !== undefined) {
          $svgPcontainer.addClass("go-p");
          $homeVideoPromise_.then(function() {
            $svgPcontainer.addClass("go-video-sound");
            $homeVideo_.play();
            //audio only plays once
            if ( !homeAudioPlayed ) {
              $homeAudio_.play();
              homeAudioPlayed = true;
            }
            videoEndedControl(true);
            if ( !_audioGlobal ) {
              $homeVideo_.volume=0;
              $homeAudio_.volume=0;
            }
          }).catch(function(error) {
            //error
          });
        }
      }
    }

    //*** GINASIOS GYM *** //
    $gymBlock.each(function(i) {
      var $this = $(this),
          $fixedthis = $this.find(".visual"),
          $whatInfo = $this.find(".info");

      //fix elements
      if(!$_html.hasClass("safari"))  $fixedthis.removeClass("fixed");
      if ( verge.inY($this, 0) ) {
        if (verge.rectangle($fixedthis).top <= 10) {
            $gymFixedBlock.removeClass("fixed");
            $fixedthis.addClass("fixed");
        }
      } else {
        if($_html.hasClass("safari"))  $fixedthis.removeClass("fixed");
      }
    })//end each gym

    $gymPics.each(function(i) {
      var $this = $(this);
      if( yScroll >= height_gymInfoTrigger[i].iniScroll && yScroll <= height_gymInfoTrigger[i].endScroll ) {
          var b = height_gymInfoTrigger[i].endScroll,
              curve = -yScroll+b;
          $this.css("clip", "rect("+curve+"px, "+_globalViewportW+"px, "+_globalViewportH+"px, 0px)");
      } else if ( yScroll > height_gymInfoTrigger[i].endScroll ) {
          $this.css("clip", "rect(0px, "+_globalViewportW+"px, "+_globalViewportH+"px, 0px)");
      }else{
          $this.css("clip", "rect("+_globalViewportH+"px, "+_globalViewportW+"px, "+_globalViewportH+"px, 0px)");
      }
    });//end each

    // Gyms svg
    $gymInfo.each(function(i) {
      var $this = $(this),
          $svgAnim = $this.find(".home-icon"),
          $txtAnim = $this.find(".break span");
      if ( verge.inY($this, -_globalOneFiveViewportH) ) {
          if ( $this.hasClass("goAnim") ) return;
          $this.addClass("goAnim");
          TweenMax.staggerTo($txtAnim, .4, {
            y: 0,
            ease: Circ.easeOut
          }, .02);
          if ( $svgAnim.attr("id") == "svg-modal36-anim") svgModal36Anim.play();
          if ( $svgAnim.attr("id") == "svg-crossfit-anim") svgCrossfitAnim.play();
          if ( $svgAnim.attr("id") == "svg-massag-anim") svgMassagAnim.play();
          if ( $svgAnim.attr("id") == "svg-modal24-anim") svgModal24Anim.play();
          if ( $svgAnim.attr("id") == "svg-pool-anim") svgPoolAnim.play();
          if ( $svgAnim.attr("id") == "svg-spa-anim") svgSpaAnim.play();
      }
    })//end each gym
  }

  return {
    init: init,
    kill: kill
  }
}