function horariosPage() {
  /*Variables*/
  var scrollVal = 0,
      lastScroll = -1,
      scrollLimit = 0,
      page_loop = 0,
      scrollDirection = null,
      sliderHorarios = null,
      mouseX = 0,
      container_offset = $(".horarios .content-horarios .gym-column").width()*$(".horarios .content-horarios .gym-column").length - _globalViewportW;

  /*DOM variables*/
  var $gymColumn = $(".gym-column");

  var init = function() {

    if(_customScroll == null) {
      /*Attach the event with a reference to kill after exit */
      $_window.on("scroll.horarios", rAF_scroll_HorariosP);
    }
    else {
      _page_scroll_listener = function(status) {
        horarios_scroll_rAF(status);
      };

      _customScroll.addListener(_page_scroll_listener);
    }
    console.log("horarios page");

    /*inits*/
    page_loop = _rAF_loop(animationLoop);

    if ($_body.hasClass('mobile')) {
      var sliderHorarios = new Swiper ('.horarios .content-horarios', {
        grabCursor: true,
        slidesPerView: 'auto',
        resistanceRatio: 0.5,
        navigation: {
            nextEl: '.horarios .next.gym-btn',
            prevEl: '.horarios .prev.gym-btn',
        },
        wrapperClass: 'scroll-wrapper-horizontal',
        slideClass: 'gym-column'
      })
    }

    //Events
    audioBtnShow(false);

    fCalendar_ev_showClose();
    fCalendar_start();
    fCalendar_ev_estudios();
    fCalendar_ev_modalidades();

    //DEFAULT EVENTS
    initEvents();
    if(!$_body.hasClass("mobile"))
      $_window.on("resize.resizeEventHorarios", $.debounce(500, resizeHorarios));

    if($_body.hasClass("mobile"))
      $_window.on("orientationchange.orientationEventHorarios", $.debounce(500, orientationHorarios));
  }

  var kill = function() {
    //Kill Events
    window.cancelAnimationFrame(page_loop);
    $(".horarios .content-horarios").off("mousemove");
    // VirtualScroll.off();
    if(sliderHorarios)
      sliderHorarios.destroy();

    //DEFAULT KILL EVENTS
    $_window.off("scroll.horarios");
    $_window.off("resize.resizeEventHorarios");
    $_window.off("orientationchange.orientationEventHorarios");

    _scrollRef = null;
    cancelAnimationFrame(_raf_main_id);

    if(_customScroll)
      _customScroll.removeListener(_page_scroll_listener);
  }

  /*page functions*/
  function initEvents() {
    var $noClick = $(".table-horarios a.mod-no-click");
    $noClick.on("click", function(event){
      event.preventDefault();
    });

    $gymColumn.on("mouseenter", function(){
      var $this = $(this);
      $this.find(".arrow-gyms").addClass("anim-arrow");
    })//end mouse enter

    $gymColumn.on("mouseleave", function(){
      var $this = $(this);
      $this.find(".arrow-gyms").removeClass("anim-arrow");
    })//end mouse leave

    // $(".gym-btn").on("mouseenter", function() {
    //   var $this = $(this);
    //   if($_body.hasClass('mobile')) return false;
    //   $this.find(".bg-hover").doTimeout("fOver", 100, "addClass", "over");
    //   event.preventDefault();
    // }).on("mouseleave", function() {
    //   var $this = $(this);
    //   if($_body.hasClass('mobile')) return false;
    //   $this.find(".bg-hover").doTimeout("fOver", 0, "removeClass", "over");
    //   event.preventDefault();
    // });

    if (!$_body.hasClass('mobile')) {
      $(".horarios .content-horarios").on("mousemove", function(event) {
        var $this = $(this);

        if(!$this.hasClass("stop-mousemove"))
          mouseX = event.clientX;
      })
    }
  }

  function resizeHorarios() {
    container_offset = $(".horarios .content-horarios .gym-column").width()*$(".horarios .content-horarios .gym-column").length - _globalViewportW;
  }

  function orientationHorarios() {
    var sliderHorarios = document.querySelector('.horarios .content-horarios').swiper;
    sliderHorarios.update();

    if($('.horarios .content-horarios').hasClass("open")) {
      $(".gym-btn").css("display", "");
      TweenMax.set($(".horarios .content-horarios .gym-column.open").find(".click-area"), { autoAlpha: 1 });
      TweenMax.set($(".horarios .content-horarios .gym-column.open"), { x: 0 });
      $('.horarios .content-horarios').removeClass('open');
      $.doTimeout(400, function() {
        $('.horarios .content-horarios').removeClass('stop-mousemove swiper-no-swiping');
        sliderHorarios.update();
      })
      $(".horarios .content-horarios .gym-column.open").removeClass("open");
    }
  }

  function rAF_scroll_HorariosP() {
    if (!$_body.hasClass('mobile')) {
      window.requestAnimationFrame(f_rAF_scroll_HorariosP);
    }
  }

  function animationLoop() {
    page_loop = _rAF_loop(animationLoop);

    if (!$_body.hasClass('mobile')) {
      var offset = scaleBetween(mouseX, 0, container_offset, 0, _globalViewportW);

      TweenMax.to($(".scroll-wrapper-horizontal"), 1, {
        x : -offset,
        ease: Power4.easeOut
      })
    }
  }

  function f_rAF_scroll_HorariosP(status) {
    // var $contentHorarios = $(".content-horarios").find(".gym-column.open");

    // if(!$contentHorarios.length)
    //   return false;
    //   content_horarios_top = $contentHorarios.offset().top - 80;
    // if ($_window.width() >= 768) {
    //   if ( $_window.scrollTop() >= content_horarios_top )
    //       $contentHorarios.find(".btn-close").addClass("fix");
    //   else
    //     $contentHorarios.find(".btn-close").removeClass("fix");
    // }
  }

  return {
    init: init,
    kill: kill
  }
}