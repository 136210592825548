function page404Page() {
  /*Variables*/
  $four04Video = $(".page-404 .bg-video")[0];
  $four04VideoPromise_ = $four04Video.play();

  /*DOM variables*/

  var init = function() {

    if(_customScroll == null) {
      /*Attach the event with a reference to kill after exit */
      $_window.on("scroll.error", rAF_scroll_ErrorP);
    }
    else {
      _page_scroll_listener = function(status) {
        error_scroll_rAF(status);
      };

      _customScroll.addListener(_page_scroll_listener);
    }
    console.log("error page");

    /*inits*/

    //Events
    audioBtnShow(true);

    //DEFAULT EVENTS
    initEvents();
    if(!$_body.hasClass("mobile"))
      $_window.on("resize.resizeEventError", $.debounce(500, resizeError));
  }

  var kill = function() {
    //Kill Events
    if(_loop_404 != null )
      _loop_404.stop();

    //DEFAULT KILL EVENTS
    $_window.off("scroll.error");
    $_window.off("resize.resizeEventError");

    _scrollRef = null;
    cancelAnimationFrame(_raf_main_id);

    if(_customScroll)
      _customScroll.removeListener(_page_scroll_listener);
  }

  /*page functions*/
  function initEvents() {
    var $muteBtn = $(".mute-button");

    $muteBtn.on("click", function() {
      var $this = $(this);
      if($this.hasClass("sound-on"))
        _loop_404.start("audio");
      else
        _loop_404.stop();
    });

    if ($four04VideoPromise_ !== undefined) {
      $four04VideoPromise_.then(function() {
        if (!$_body.hasClass('mobile')) {
          _loop_404 = new SeamlessLoop();
          _loop_404.addUri("/public/audio/cant-touch-this-loop.mp3", 7185, "audio");
          _loop_404.callback(soundsLoaded);
        }

        function soundsLoaded() {
          _loop_404.start("audio");
          _loop_404.volume(0.5);
        }
      }).catch(function(error) {
        //error
      });
    }
  }

  function resizeError() {

  }

  function rAF_scroll_ErrorP() {
    if (!$_body.hasClass('mobile')) {
      window.requestAnimationFrame(f_rAF_scroll_ErrorP);
    }
  }

  function f_rAF_scroll_ErrorP(status) {

  }

  return {
    init: init,
    kill: kill
  }
}