function ginasiosPage() {
  /*Variables*/
  var $gymColumn = $(".gym-column"),
      $headerGym = $(".header-ginasios"),
      sliderGymAll = null,
      page_loop = 0,
      mouseX = 0,
      container_offset = $(".header-ginasios .gym-column").width()*$(".header-ginasios .gym-column").length - _globalViewportW;

  /*DOM variables*/

  var init = function() {

    if(_customScroll == null) {
      /*Attach the event with a reference to kill after exit */
      $_window.on("scroll.ginasios", rAF_scroll_GinasiosP);
    }
    else {
      _page_scroll_listener = function(status) {
        ginasios_scroll_rAF(status);
      };

      _customScroll.addListener(_page_scroll_listener);
    }
    console.log("ginasios page");

    /*inits*/
    page_loop = _rAF_loop(animationLoop);

    if ($_body.hasClass('mobile')) {
      var sliderGymAll = new Swiper ('.header-ginasios', {
        grabCursor: true,
        slidesPerView: 'auto',
        resistanceRatio: 0.5,
        navigation: {
          nextEl: '.next.gym-btn',
          prevEl: '.prev.gym-btn',
        },
        containerModifierClass: 'header-ginasios',
        wrapperClass: 'scroll-wrapper-horizontal',
        slideClass: 'gym-column'
      })
    }

    //Events
    audioBtnShow(false);

    // if ( $_body.hasClass('mobile') ) return;

    //DEFAULT EVENTS
    initEvents();
    if(!$_body.hasClass("mobile"))
      $_window.on("resize.resizeEventGinasios", $.debounce(500, resizeGinasios));

    if($_body.hasClass("mobile"))
      $_window.on("orientationchange.orientationEventGinasios", $.debounce(500, orientationGinasios));
  }

  var kill = function() {
    //Kill Events
    window.cancelAnimationFrame(page_loop);
    $headerGym.off("mousemove");
    if(sliderGymAll)
      sliderGymAll.destroy();

    //DEFAULT KILL EVENTS
    $_window.off("scroll.ginasios");
    $_window.off("resize.resizeEventGinasios");
    $_window.off("orientationchange.orientationEventGinasios");

    _scrollRef = null;
    cancelAnimationFrame(_raf_main_id);

    if(_customScroll)
      _customScroll.removeListener(_page_scroll_listener);
  }

  /*page functions*/
  function initEvents() {
    $gymColumn.find(".gym-link").on("mouseenter", function(){
      if ( $headerGym.hasClass("clickall") ) return;
      var $this = $(this);
      $this.find(".arrow-gyms").addClass("anim-arrow");
    })//end mouse enter

    $gymColumn.find(".gym-link").on("mouseleave", function(){
      if ( $headerGym.hasClass("clickall") ) return;
      var $this = $(this);
      $this.find(".arrow-gyms").removeClass("anim-arrow");
    })//end mouse leave

    $gymColumn.on("mouseenter", function(){
      if ( $headerGym.hasClass("clickall") ) return;
      var $this = $(this);
      $this.addClass("js-gym-anime");
    })//end mouse enter

    $gymColumn.on("mouseleave", function(){
      if ( $headerGym.hasClass("clickall") ) return;
      var $this = $(this);
      $this.removeClass("js-gym-anime");
    })//end mouse leave

    if (!$_body.hasClass('mobile')) {
      $gymColumn.find(".gym-link").on("click", function(){
        var $this = $(this),
            thisHrefAjax = $this.attr("data-ajaxUrl"),
            newContent = thisHrefAjax,
            scrollVal = verge.scrollY(),
            speed = null,
            curve = null;

        if (scrollVal > 0) {
          speed = 600;
          curve = "easeInOutQuint";
        } else {
          speed = 0;
          curve = 0;
        }

        goTo($_body, speed, curve, null);
        $headerGym.addClass("clickall");

        $.doTimeout(speed, function() {
          TweenMax.set($this.parent(), {
            width: _globalViewportW
          });
          TweenMax.set($this.parent().find(".block-bg-cover"), {
            x: 0,
            roundProps: "x"
          });
          TweenMax.to($this.parent(), 1, {
            x: "-=" + $this.parent().offset().left,
            roundProps: "x",
            zIndex: 1,
            ease: Expo.easeOut,
            onComplete: function() {
              $.doTimeout(500, function() {
                loadPagesSame(newContent);
              })
            }
          });
        })

        return;
      })//end click
    }

    // $(".gym-btn").on("mouseenter", function() {
    //   var $this = $(this);
    //   $this.find(".bg-hover").doTimeout("fOver", 100, "addClass", "over");
    //   event.preventDefault();
    // }).on("mouseleave", function() {
    //   var $this = $(this);
    //   $this.find(".bg-hover").doTimeout("fOver", 0, "removeClass", "over");
    //   event.preventDefault();
    // });

    if (!$_body.hasClass('mobile')) {
      $headerGym.on("mousemove", function(event) {
        var $this = $(this);

        if(!$this.hasClass("clickall"))
          mouseX = event.clientX;
      })
    }
  }

  function resizeGinasios() {
    container_offset = $(".header-ginasios .gym-column").width()*$(".header-ginasios .gym-column").length - _globalViewportW;
  }

  function orientationGinasios() {
    var sliderGymAll = document.querySelector('.header-ginasios').swiper;
    sliderGymAll.update();
  }

  function rAF_scroll_GinasiosP() {
    if (!$_body.hasClass('mobile')) {
      window.requestAnimationFrame(f_rAF_scroll_GinasiosP);
    }
  }

  function animationLoop() {
    page_loop = _rAF_loop(animationLoop);

    if (!$_body.hasClass('mobile')) {
      var offset = scaleBetween(mouseX, 0, container_offset, 0, _globalViewportW);

      TweenMax.set($(".scroll-wrapper-horizontal"), {
        x : -offset
      })
    }
  }

  function f_rAF_scroll_GinasiosP(status) {

  }

  return {
    init: init,
    kill: kill
  }
}