function ginasioPage() {
  /*Variables*/
  var $form = $("#form-visita"),
    $formInputs = $("#form-visita input"),
    $submitForm = $form.find("button");

  var $addClassAnim = $(".to-anim"),
    $addClassAnimNow = $(".to-anim-now"),
    whenViewport = Math.round(_globalViewportH / 1.4);
  var $$gymVideo = $("#gym-video"),
    videoTotalTime = $$gymVideo.attr("data-time"),
    $gymVideo = $("#gym-video")[0];

  if (!$_body.hasClass("mobile")) $gymVideoPromise_ = $gymVideo.play();

  /*DOM variables*/

  var init = function () {
    if (_customScroll == null) {
      /*Attach the event with a reference to kill after exit */
      $_window.on("scroll.ginasio", rAF_scroll_GinasioP);
    } else {
      _page_scroll_listener = function (status) {
        ginasio_scroll_rAF(status);
      };

      _customScroll.addListener(_page_scroll_listener);
    }
    console.log("ginasio page");

    /*inits*/

    //Events
    audioBtnShow(false);

    if (!$_body.hasClass("mobile")) {
      if ($gymVideoPromise_ !== undefined) {
        $gymVideoPromise_
          .then(function () {})
          .catch(function (error) {
            //error
          });
      }
    }

    //DEFAULT EVENTS
    initEvents();
    if (!$_body.hasClass("mobile"))
      $_window.on("resize.resizeEventGinasio", $.debounce(500, resizeGinasio));
  };

  var kill = function () {
    //Kill Events
    videoEndedControlGinasios(false);

    //DEFAULT KILL EVENTS
    $_window.off("scroll.ginasio");
    $_window.off("resize.resizeEventGinasio");

    _scrollRef = null;
    cancelAnimationFrame(_raf_main_id);

    if (_customScroll) _customScroll.removeListener(_page_scroll_listener);
  };

  /*page functions*/
  function initEvents() {
    const input = document.querySelector(".mce-CONTACT-gym");
    window.intlTelInput(input, {
      utilsScript:
        "https://cdn.jsdelivr.net/npm/intl-tel-input@19.3.0/build/js/utils.js",
      initialCountry: "pt",
      showSelectedDialCode: true,
      separateDialCode: true,
    });

    $(".ginasios .content-header .center-stage").addClass("go");

    $form.find(".rgpd-wrapper svg").on("click", function () {
      var $this = $(this);
      $this.closest("div").toggleClass("active");

      if ($this.closest("div").hasClass("active")) {
        $this.closest("div").find("input").prop("checked", true);
        $form.find("button").addClass("enable");
      } else {
        $this.closest("div").find("input").prop("checked", false);
        $form.find("button").removeClass("enable");
      }
    });

    $formInputs.blur(function () {
      var $this = $(this),
        defeito = this.defaultValue;

      if (!$this.hasClass("required") && !$this.val()) return;
      check($this, defeito, 0);

      if ($this.hasClass("erro")) {
        $this.parent().addClass("erro");
      } else {
        $this.parent().removeClass("erro");
      }
    });

    $formInputs.focus(function () {
      var $this = $(this);
      $this.removeClass("erro");
      $this.parent().removeClass("erro");
    });

    console.log(document.getElementById("form-visita").dataset.gym);

    let company, jns, qzq;

    console.log(document.getElementById("form-visita").dataset.gym);

    if (document.getElementById("form-visita").dataset.gym === "celas") {
      company = "Celas Health Club";
      jns = "Celas Fitness Club";
      qzq = "https://phive.pt/ginasios/celas-fitness-club/";
    } else if (
      document.getElementById("form-visita").dataset.gym === "lagrimas"
    ) {
      company = "Lágrimas Health Club";
      jns = "Lágrimas Health Club";
      qzq = "https://phive.pt/ginasios/lagrimas-health-club/";
    } else if (
      document.getElementById("form-visita").dataset.gym === "leiria"
    ) {
      company = "Leiria Health Club";
      jns = "Leiria Health Club";
      qzq = "https://phive.pt/ginasios/leiria-health-club/";
    }

    /*Submit Form*/
    $submitForm.on("click", function () {
      event.preventDefault();

      console.log(validateForm($form));

      // validateForm($form) &&
      if (!$form.hasClass("sending")) {
        console.log("sending");

        formAnimLoadingNEW($submitForm, "loading");
        $form.addClass("sending");

        $.doTimeout(2700, function () {
          formAnimLoadingNEW($submitForm, "sent-ok");
          $form.removeClass("sending");
        }); //end loading simulation

        const requestOptions = {
          method: "POST",
          mode: "no-cors",
        };

        const oid = "00D7R000005UkoR"; // Change this // Test env -> 00D9E000000ABx6  // LIVE 00D7R000005UkoR
        const firstName = document.querySelector(".mce-NAME-gym").value;
        const email = document.querySelector(".mce-EMAIL-gym").value;
        const mobile = document.querySelector(".mce-CONTACT-gym").value;
        const area = document.querySelector(
          ".iti__selected-dial-code"
        ).innerHTML;

        const noSpace = mobile.replace(/\s/g, "");
        const noDash = noSpace.replace("-", "");

        const parsedMobile = noDash;

        const urlQueries =
          `&oid=${oid}` +
          "&retUrl=" +
          `&last_name=${firstName}` +
          `&email=${email}` +
          `&00NTX000000PMVB=${area}` +
          `&mobile=${parsedMobile}` +
          `&00N7R00000DdJns=${jns}` +
          "&00N7R00000FqcAN=1" +
          `&company=${company}` +
          "&lead_source=LeadWebsite" +
          "&00N7R00000DdOx0=ScheduleVisit" +
          `&00N7R00000DdQZQ=${qzq}`;

        console.log(
          `https://webto.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8${urlQueries}`
        );

        fetch(
          `https://webto.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8${urlQueries}`,
          requestOptions
        )
          .then((response) => response.text())
          .then((result) => console.log(result))
          .catch((error) => console.log("error", error));
      }
    });

    if (!$_body.hasClass("mobile")) {
      videoEndedControlGinasios(true);
    }
  }

  //*** VIDEO *** //
  function videoEndedControlGinasios(start) {
    if (!start) {
      $.doTimeout("videoisplayingGinasios", false);
      return false;
    }
    var $toGoHere = $(".content-main-wrapper");
    if (!$gymVideo) return;
    $.doTimeout("videoisplayingGinasios", 40, function () {
      if ($gymVideo.currentTime >= videoTotalTime && !$gymVideo.paused) {
        goTo($toGoHere, 1200, [0.7, 0, 0.3, 1], 0);
        return false;
      }
      return true;
    }); //end timeout
  } //end videoEndedControl

  function resizeGinasio() {}

  function rAF_scroll_GinasioP() {
    if (!$_body.hasClass("mobile")) {
      window.requestAnimationFrame(f_rAF_scroll_GinasioP);
    }
  }

  function f_rAF_scroll_GinasioP(status) {
    var scrollVal = verge.scrollY();
    var dataDelay = 0;
    //
    $addClassAnim.each(function (i) {
      var $this = $(this);
      if (verge.inY($this, -whenViewport)) {
        if ($this.hasClass("anim-go")) return;
        $this.addClass("anim-go");
      } //end verge
    }); //end each

    $addClassAnimNow.each(function (i) {
      var $this = $(this);
      if (verge.inY($this, 0)) {
        if ($this.hasClass("anim-go")) return;
        $this.addClass("anim-go");
      } //end verge
    }); //end each

    //video
    if ($_body.hasClass("mobile") || !$gymVideo) return;
    if (scrollVal >= _globalViewportH - 130) {
      if ($gymVideoPromise_ !== undefined) {
        $gymVideoPromise_
          .then(function () {
            $gymVideo.pause();
          })
          .catch(function (error) {
            //error
          });
      }

      videoEndedControlGinasios(false);
    } else {
      videoEndedControlGinasios(true);

      if ($gymVideoPromise_ !== undefined) {
        $gymVideoPromise_
          .then(function () {
            $gymVideo.play();
          })
          .catch(function (error) {
            //error
          });
      }
    }
  }

  return {
    init: init,
    kill: kill,
  };
}
