function teamPage() {
  /*Variables*/
  _$titleSVGteam = $(".team-main .bg"),
  _yEnd = _globalViewportH / 2,
  _mTitle_SVGteam = (0.95 - 0.15) / (_yEnd - 0);

  $teamVideo = $(".section-group-all video")[0];

  if (!$_body.hasClass('mobile'))
    $teamVideoPromise_ = $teamVideo.play();

  /*DOM variables*/
  var $navCities = $(".cities-picker");

  var init = function() {

    if(_customScroll == null) {
      /*Attach the event with a reference to kill after exit */
      $_window.on("scroll.team", rAF_scroll_TeamP);
    }
    else {
      _page_scroll_listener = function(status) {
        Team_scroll_rAF(status);
      };

      _customScroll.addListener(_page_scroll_listener);
    }
    console.log("team page");

    /*inits*/

    //Events
    if (!$_body.hasClass('mobile')) {
      if ($teamVideoPromise_ !== undefined) {
        $teamVideoPromise_.then(function() {

        }).catch(function(error) {
          //error
        });
      }
    }

    $(".cities-picker ul li").on("click", function() {
      var $this = $(this),
          target = $this.attr("data-target");

      TweenMax.to(".cities-picker ul li", .5, {
        className:"-=active"
      });

      TweenMax.to($this, .5, {
        className:"+=active"
      });

      if(target == 'all') {
        TweenMax.to(".nav-modality-list li", .5, {
          opacity: 1,
          pointerEvents: "initial"
        });
      }
      else {
        TweenMax.to(".nav-modality-list li", .5, {
          opacity: .2,
          pointerEvents: "none"
        });

        TweenMax.to("."+target, .5, {
          opacity: 1,
          pointerEvents: "initial"
        });
      }
    })

    audioBtnShow(false);

    if ( $_body.hasClass('mobile') ) return;

    //DEFAULT EVENTS
    initEvents();
    if(!$_body.hasClass("mobile"))
      $_window.on("resize.resizeEventTeam", $.debounce(500, resizeTeam));
  }

  var kill = function() {
    //Kill Events
    _$titleSVGteam = null,
    _mTitle_SVGteam = null,
    _yEnd = null;

    //DEFAULT KILL EVENTS
    $_window.off("scroll.team");
    $_window.off("resize.resizeEventTeam");

    _scrollRef = null;
    cancelAnimationFrame(_raf_main_id);

    if(_customScroll)
      _customScroll.removeListener(_page_scroll_listener);
  }

  /*page functions*/
  function initEvents() {

  }

  function resizeTeam() {

  }

  function rAF_scroll_TeamP() {
    if (!$_body.hasClass('mobile')) {
      window.requestAnimationFrame(f_rAF_scroll_TeamP);
    }
  }

  function f_rAF_scroll_TeamP(status) {
    var scrollVal = verge.scrollY();

    var opac = _mTitle_SVGteam * scrollVal + 0.15;
    if (scrollVal > _yEnd) {
        opac = .95;
    }
    if (scrollVal <= 20) {
        opac = .15;
    }
    _$titleSVGteam.css("opacity", opac);

    if (verge.inY($(".trigger"), -200)) {
      TweenMax.to($navCities, .5, {
        autoAlpha: 0
      });
    } else {
      TweenMax.to($navCities, .5, {
        autoAlpha: 1
      });
    }
  }

  return {
    init: init,
    kill: kill
  }
}